import React, { useEffect, useState } from "react"
import { DashboardNavbar } from "../headers/DashboardHeader"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import { Spinner } from "@material-tailwind/react"
import { privateRoutes, investRoutes } from "../../routes"
import SidebarContent from "../Sidebar/SidebarContent"
import { MobileSidebar } from "../Sidebar/MobileSidebar"
import { useLazyQuery, useQuery } from "@apollo/client"
import { CHECK_PIN } from "../../GraphQl/queries"
import { RootState } from "../../store/store"
import { useSelector } from "react-redux"

const DashboardContent = () => {
  const [routes, setRoutes] = useState<any[]>([])
  const history = useHistory()
  const [showAlert, setShowAlert] = useState(false)
  const { companyId, usertype, userId } = useSelector(
    (state: RootState) => state.auth
  )

  useEffect(() => {
    if (localStorage.getItem("usertype") === "staff") {
      setRoutes(privateRoutes)
    } else {
      setRoutes(privateRoutes)
    }
  }, [])

  const { data, loading, error } = useQuery(CHECK_PIN, {
    variables: {
      id: usertype === "staff" ? userId : companyId,
    },
  })
  console.log(data)

  useEffect(() => {
    if (data && data.user_pin && data.user_pin.length === 0) {
      setShowAlert(true)
    }
  }, [data])

  return (
    <>
      <div className="flex bg-black h-full w-[100vw] ">
        <div className="w-80 h-screen hidden xl:flex">
          <SidebarContent />
        </div>
        <div className="m-0 md:m-2 lg:m-5 w-[100vw] overflow-x-auto overflow-y-auto">
          {showAlert && (
            <div className="  rounded-md w-full border-red-400 bg-red-100 text-center py-2 px-3 text-red-500 text-[1.1rem]">
              Pin has not been set, Click{" "}
              <Link to="/dashboard/settings" className=" underline px-1">
                Here
              </Link>{" "}
              to set a new Pin
            </div>
          )}
          <div className="dashboard-container w-full   h-[95vh] px-4 lg:px-5 bg-[#222020] rounded-3xl transition-all lg:rounded-3xl md:rounded-3xl">
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes?.map((route, index) => (
                  <Route
                    path={route.layout + route.path}
                    component={route.component}
                    exact={route.exact}
                    key={index}
                  />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardContent

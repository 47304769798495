import { useQuery, gql } from "@apollo/client"

//---check userPIN

export const CHECK_PIN = gql`
  query checkifPinisSet($id: uuid) {
    user_pin(where: { user_id: { _eq: $id } }) {
      id
      pin
    }
  }
`

export const GETBRANCHESSEARCH = gql`
  query MyQuery($searchText: String) {
    view_commission_rate_search(
      where: {
        _or: [
          { class_name: { _ilike: $searchText } }
          { description: { _ilike: $searchText } }
          { rate: { _ilike: $searchText } }
          { subclass_name: { _ilike: $searchText } }
          { user_type: { _ilike: $searchText } }
        ]
      }
    ) {
      class_name
      cover_type
      created_at
      created_by
      description
      id
      rate
      status
      subclass_name
      user_type
    }
  }
`
export const GET_COMPREHENSIVE = gql`
  query getComprehensiveRisks {
    comprehensive_third_party_risk_class {
      id
      created_at
      comprehensive
      own_damage_excess_rate
      risk_id
      third_party_rate_theft
      risk_class {
        id
        name
      }
    }
  }
`
export const GET_DISCOUNT_RATE = gql`
  query getAllDiscount {
    discount {
      id
      name: description
      rate
      status
    }
  }
`

export const GET_DISCOUNT_RATES = gql`
  query MyQuery($company_id: uuid) {
    company_discounts(
      where: { company_id: { _eq: $company_id } }
      distinct_on: discount_id
    ) {
      discount {
        id
        name
        description
        businessClassByBusinessClass {
          id
          name
        }
      }
      created_at
      id
    }
  }
`
export const GET_ADDITIONAL_BENEFITS = gql`
  query GETADDITIONALBENEFIT {
    additional_benefit {
      created_at
      description
      id
      name
      status
    }
  }
`
export const GET_ADDITIONAL_BENEFIT = gql`
  query myQuery($company_id: uuid) {
    company_additional_benefits(where: { company_id: { _eq: $company_id } }) {
      benefit: additional_benefit {
        id
        name
        description
        businessClassByBusinessClass {
          id
          name
        }
      }
      created_at
      id
    }
  }
`

export const GET_ADDITIONAL_BENEFIT_RATE = gql`
  query MyQuery($id: uuid) {
    benefit_rate(where: { company_benefit_id: { _eq: $id } }) {
      subclass {
        id
        name
      }
      status
      rate
      id
      covertype {
        id
        name
      }
      company_additional_benefit {
        id
        additional_benefit {
          id
          name
          businessClassByBusinessClass {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_COVERTYPES_SUBCLASS = gql`
  query MyQuery($sub_class_id: uuid, $company_id: uuid) {
    company_subclass_covertypes(
      where: {
        company_id: { _eq: $company_id }
        sub_class_id: { _eq: $sub_class_id }
      }
    ) {
      id
      covertypes
      description
      business_class {
        id
        name
        subclasses {
          id
          name
        }
      }
    }
  }
`

//-------invest-2-insure----------------

//----driverQuery------------

export const GET_ALL_DRIVERS = gql`
  query MyQuery {
    view_driver_policy_totals_details(order_by: { date_created: desc }) {
      account_status
      balance
      date_created
      driver_code
      usertype
      total_arrears
      created_by
      driver_id
      driver_name
      phone_number
      total_number_of_policies
      total_premium_balance
      total_premium_paid
      total_premium_payable
      wallet_number
    }
  }
`
export const GET_SINGLE_DRIVER = gql`
  query MyQuery($id: uuid) {
    collection_request(where: { driver_id: { _eq: $id } }) {
      amount
      created_at
      created_by
      driver_current_balance
      id
      payment_mode
      status
      reference_number
      driver_id
      company_staff {
        id
        name
        phone_number: phone_number_one
      }
      sub_agent {
        id
        first_name
        last_name
        phone_number
      }
    }
  }
`

export const GET_DRIVER_PAYMENTS = gql`
  query MyQuery($id: uuid) {
    driver_invest2insure_payment(
      where: { driver_id: { _eq: $id } }
      order_by: { datecreated: desc }
    ) {
      amount
      company_staff {
        id
        name
        phone_number: phone_number_one
      }
      datecreated
      id
      sub_agent {
        id
        first_name
        last_name
      }
      transaction_id
      policy {
        policy_number
        request {
          amount
          registration_number_text
        }
      }
      premium_balance
    }
  }
`

export const AVAILABLE_DRIVERS = gql`
  query MyQuery {
    view_eligible_drivers(where: { status: { _eq: "eligible" } }) {
      balance
      id
      name
      no_of_policies
      phone: phone_number_1
      status
    }
  }
`
export const REPLENISHMENT_RECIEPTS = gql`
  query MyQuery($id: uuid) {
    view_cash_cheque_payment_summary(
      order_by: { date_replenished: desc }
      where: { insurance_company: { _eq: $id } }
    ) {
      date_replenished
      no_of_transactions
      insurance_company
      payment_type
      reference_number
      replenished_by_name
      staff_number
      payee_code
      payee_name
      total_amount
    }
  }
`
export const REPLENISHMENT_RECEIPTS_PAY_CASH = gql`
  query MyQuery($ref_number: String) {
    cash_payment(
      order_by: { date_created: desc }
      where: { reference_number: { _eq: $ref_number } }
    ) {
      amount
      reference_number
      date_replenished: date_updated
      policy {
        policy_number
        debit_number
      }
    }
  }
`

export const REPLENISHMENT_RECEIPTS_COLLECTION = gql`
  query MyQuery($ref_number: String) {
    collection_request(
      order_by: { created_at: desc }
      where: { replenish_reference: { _eq: $ref_number } }
    ) {
      amount
      reference_number
      date_replenished: updated_at
      replenished_by
      replenished_by_name
      payment_mode
      driver {
        full_name
      }
    }
  }
`
export const REPLENISHMENT_RECEIPTS_PAY_CHEQUE = gql`
  query MyQuery($ref_number: String) {
    cheque_payment(
      order_by: { created_at: desc }
      where: { reference_number: { _eq: $ref_number } }
    ) {
      amount
      reference_number
      date_replenished: updated_at
      policy {
        policy_number
        debit_number
      }
    }
  }
`

export const LIST_OF_ALL_DRIVER_POLICIES = gql`
  query MyQuery($id: uuid) {
    view_get_driver_policy_details(where: { driver_id: { _eq: $id } }) {
      driver_name
      driver_code
      policy_id
      policy_number
      premium_balance
      premium_paid
      premium_payable
      policyholder
      registration_number
      scheduled_amount
      start_date
      end_date
    }
  }
`

export const AMOUNT_PAYABLE_EVERYDAY = gql`
  query MyQuery($policy_id: uuid) {
    driver_payments_schedule(
      where: { policy_id: { _eq: $policy_id } }
      order_by: { scheduled_date: asc }
    ) {
      amount_payable
      ageing
      actual_amount_paid
      arrears
      balance_after
      status
      scheduled_date
    }
  }
`
export const GET_DRIVER_CLOSE_ACCOUNT_REQUESTS = gql`
  query MyQuery {
    drivers(where: { account_status: { _eq: "active" } }) {
      account_status
      address
      agent_id
      balance
      bank_details
      created_at
      created_by
      driver_code
      email
      first_name
      full_name
      gender
      id_number
      is_deleted
      other_name
      phone_number_1
      phone_number_2
      surname
      station
      updated_at
      sub_agent {
        id
        last_name
        first_name
        phone_number
      }
      company_staff {
        id
        name
        phone_number: phone_number_one
      }
    }
  }
`

export const DRIVER_WITHDRAWAL_REQUESTS = gql`
  query MyQuery {
    driver_withdrawal_request(
      where: { status: { _eq: "pending" }, driver_approval: { _eq: true } }
    ) {
      id
      reference_number
      amount
      driver {
        momo_wallet_details
        phone_number: phone_number_1
        full_name
        driver_code
        address
        balance
        id
        driver_station {
          id
          name
        }
      }
      company_staff {
        id
        name
        phone_number_one
      }
      created_at
      driver_approval
      sub_agent {
        id
        phone_number
        last_name
        first_name
      }
      driver_current_balance
    }
  }
`

//----driversQuery------------
//----trasctions---------
export const GET_ALL_AGENTS_COMMISSION = gql`
  query getAllPayabaleCommissions(
    $id: uuid!
    $status_type: String
    $com_type: String
  ) {
    view_payable_commissions_company(
      where: {
        insurance_company: { _eq: $id }
        status: { _eq: $status_type }
        commission_type: { _eq: $com_type }
      }
      order_by: { created_at: desc }
    ) {
      payable_commission_id
      account_details
      agent_first_name
      premium
      policy_number
      agent_last_name
      staff_name
      user_type
      created_at
      commission_name
      sub_class_id
      risk_id
      risk_or_sub_class_name
      reference_amount
      gross_amount
      rate
      net_amount
      total_tax_deductions
      status
      business_class_name
      total_premium_arrears
    }
  }
`
//-------inverst-2-insure

export const GET_THIRDPARTY = gql`
  query getThirdParty {
    third_party_only_risk_class {
      basic_premium
      created_at
      fee_amount
      id
      loading_amount
      seat_amount
      total_amount
      updated_at
      risk_class {
        id
        name
      }
    }
  }
`
export const GET_COMMIISSION_RATES = gql`
  query MyQuery($id: uuid) {
    commisions_rates(where: { id: { _eq: $id } }) {
      commision_id
      business_class: class_id
      subclass_id
      intermediary_type
      rate
      description
      cover_type
    }
  }
`

export const GET_ALLRISKCLASS = gql`
  query getAllRiskClassItems {
    risk_class {
      id
      name
      status
    }
  }
`

export const GET_ROLES = gql`
  query MyQuery {
    roles {
      name
      id
    }
  }
`

export const GET_INTERMEDIARIES = gql`
  query MyQuery {
    intemediary(where: { intermediary_type: { _eq: "broker" } }) {
      active_status
      created_at
      email
      id
      intermediary_type
      name
      phone_number
    }
  }
`

export const GET_ALL_SETUPS = gql`
  query getAllsetups {
    countries: country(order_by: { name: asc }) {
      id
      is_active
      iso_code
      name
      nationality
      created_at
    }
    discounts: discount {
      created_at
      id
      rate
      description
      status
    }
    banks: bank(order_by: { name: asc }) {
      code
      created_at
      id
      is_active
      name
    }
    body_types: body_type(order_by: { name: asc }) {
      id
      created_at
      is_active
      name
    }
    vehicle_makes: vehicle_make(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
      vehicle_models(
        order_by: { name: asc }
        where: { is_active: { _eq: true } }
      ) {
        id
        name
      }
    }
  }
`

export const GET_REGIONS = gql`
  query getAllRegions {
    regions {
      id
      name
    }
  }
`
export const GET_BUSINESS_CLASSES = gql`
  query MyQuery {
    business_classes {
      id
      name
    }
  }
`

export const GET_SUBCLASSES = gql`
  query GETALLSUBCLASSOFCOMPANY($company_id: uuid, $business_class: uuid) {
    company_subclass_covertypes(
      where: {
        company_id: { _eq: $company_id }
        business_class: { id: { _eq: $business_class } }
      }
      distinct_on: sub_class_id
    ) {
      description
      id
      subclass {
        id
        name
      }
      covertypes
    }
  }
`
export const GET_PERILS_RATES = gql`
  query MyQuery($company_id: uuid, $cov_id: uuid) {
    covertypes_perils_rate(
      where: {
        company_id: { _eq: $company_id }
        covertype_id: { _eq: $cov_id }
      }
    ) {
      company_id
      perils_rates
      id
    }
  }
`

export const COVER_TYPES_PERILS = gql`
  query MyQuery($cov_id: uuid, $com_id: uuid) {
    coverage(
      where: { cover_type_id: { _eq: $cov_id }, company_id: { _eq: $com_id } }
    ) {
      covertype {
        id
        name
      }
      peril {
        id
        name
        description
      }
      created_at
      id
    }
  }
`

export const GET_PERILS = gql`
  query MyQuery($id: uuid) {
    perils(where: { business_class: { id: { _eq: $id } } }) {
      id
      name
      status
    }
  }
`

export const GET_PREMIUM_RATES = gql`
  query getPremiumRate($id: uuid) {
    premium_rate(where: { company_id: { _eq: $id } }) {
      company_id
      created_at
      id
      description
      rate
      status
      covertype {
        id
        name
      }
      subclass {
        id
        name
      }
      business_class {
        id
        name
      }
    }
  }
`

export const GET_ALL_DISTRICTS = gql`
  query getAllDistrictofRegion($regionId: uuid) {
    districts(where: { region_id: { _eq: $regionId } }) {
      id
      name
      category
      capital
      region {
        name
      }
    }
  }
`

export const GET_USERS_SEARCH = gql`
  query GetUsers($id: uuid) {
    company_staff(where: { company_id: { _eq: $id } }) {
      name
      id
      phone_number: phone_number_one
      staff_number
      email
    }
    company_agents: sub_agents(
      where: { intemediary: { company_id: { _eq: $id } } }
    ) {
      agent_code
      id
      agent_meta_data
      email
      first_name
      last_name
      phone_number
    }
    brokers: sub_agents(
      where: { intemediary: { intermediary_type: { _eq: "broker" } } }
    ) {
      agent_code
      id
      agent_meta_data
      email
      first_name
      last_name
      phone_number
    }
  }
`

export const GET_ALL_TOWNS = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
    }
  }
`

export const GET_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`

export const GET_ALL_CLIENT = gql`
  query getAllIntermediaries {
    intemediary {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      clientTypeByClientType {
        id
        name
      }
      active_status
      branch_code
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
    insurer_companies {
      bank_details
      client_type
      email
      id
      momo_details
      phone
      name
      tin_number
      clientTypeByClientType {
        id
        name
      }
    }
  }
`

export const GET_ROLE_PERMISSIONS = gql`
  query getRolePermissions($role_id: uuid) {
    role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        name
      }
      selected_actions
    }
  }
`

export const COMPANY_ROLES_PERMISSION = gql`
  query MyQuery($id: uuid) {
    company_role_permissions(where: { company_role: { id: { _eq: $id } } }) {
      selected_actions
      permission_setup {
        name
      }
    }
  }
`

export const GET_ALL_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`

export const GET_ALL_DIVISIONS = gql`
  query getAllDivisionsWOrkGroup($id: uuid!) {
    divisions(where: { insurance_company_id: { _eq: $id } }) {
      description
      id
      name
    }
  }
`

export const GET_ALL_TOWNS_OF_DISTRICT = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
      district {
        region {
          name
        }
        name
      }
    }
  }
`

export const GETSUBCLASSES = gql`
  query getSubclassesofClass($class_id: uuid) {
    subclass(where: { class_id: { _eq: $class_id } }) {
      id
      name
      status
      description
      business_class {
        name
        description
      }
    }
  }
`

export const GETBUSINESSCLASSES = gql`
  query getAllBusinessClasses {
    business_classes {
      id
      image_url
      name
      description
    }
  }
`

export const GETALLPERILS = gql`
 query getAllPerils($id:uuid) {
  perils(where: {business_class: {id: {_eq: $id}}}) {
    class_id
    description
    id
    name
    status
  }
}

`

export const GETALLEXCLUSIONS = gql`
  query getAllExclusions {
    exclusions {
      id
      name
      description
      status
    }
  }
`

export const GETALLPERILSNEXCLUSIONSOFCOVERTYPE = gql`
  query getAllPerilsNExclusionsOfCoverType($id: uuid!) {
    view_coverage_details(where: { cover_type_id: { _eq: $id } }) {
      exclusion_name
      exclusions_id
      exclusion_description
      peril_description
      peril_id
      peril_name
    }
  }
`

export const GETPREMIUMRATES = gql`
  query getAllPremiumRates {
    view_premium_rate {
      class_id
      class_name
      covertype_id
      covertype_name
      id
      premium_description
      premium_rate
      premium_rate_created_at
      subclass_id
      subclass_name
    }
  }
`

export const GETCOVERTYPES = gql`
  query getCoverTypes {
    covertypes {
      description
      id
      name
      class_id
      status
    }
  }
`
export const GET_COVER_TYPES = gql`
  query MyQuery($sub_class_id: uuid, $company_id: uuid) {
    company_subclass_covertypes(
      where: {
        company_id: { _eq: $company_id }
        sub_class_id: { _eq: $sub_class_id }
      }
    ) {
      covertypes
    }
  }
`
export const GET_ALL_PREMIUMS = gql`
query MyQuery($company_id: uuid) {
  company_subclass_covertypes(where: {company_id: {_eq: $company_id}}) {
    id
    covertypes
    subclass{
      id
      name
    }
    description
    business_class {
      id
      name
      
    }
  }
}
`
export const GET_ALL_PREMIUMS2 = gql`
query MyQuery($company_id: uuid,$class_id:uuid) {
  company_subclass_covertypes(where: {company_id: {_eq: $company_id}, business_class_id: {_eq: $class_id}}) {
    id
    covertypes
    subclass {
      id
      name
    }
    description
    business_class {
      id
      name
    }
  }
}
`
export const GET_SUB_CLASS = gql`
  query getSUbclassFromBusinessClass($id: uuid) {
    subclass(where: { business_class: { id: { _eq: $id } } }) {
      id
      name
    }
  }
`

export const GETALLDISCOUNTS = gql`
  query getAllDiscounts {
    system_discount {
      description
      id
      name
      status
    }
  }
`

export const GETDISCOUNTRATESOFDISCOUNT = gql`
  query getDiscountRatesOfDiscount($discount_id: uuid!) {
    view_discount_rates(where: { dicount_id: { _eq: $discount_id } }) {
      class_name
      subclass_name
      cover_type_name
      description
      rate
      id
      dicount_id
      discount_name
    }
  }
`
export const GET_LIST_OF_DISCOUNT = gql`
  query MyQuery($id: uuid) {
    discount_rates(where: { discount_id: { _eq: $id } }) {
      subclass {
        id
        name
      }
      status
      rate
      id
      covertype {
        id
        name
      }
      company_discount {
        id
        discount {
          id
          name
          businessClassByBusinessClass {
            id
            name
          }
        }
      }
    }
  }
`
export const PERIL_COVERAGE_RATE = gql`
query MyQuery($id: uuid) {
  coverage_rate(where: {company_coverage_id: {_eq: $id}}) {
    default_rate
    id
    max_rate
    min_rate
    status
    coverage {
      peril {
        id
        name
      }
    }
  }
}
`
export const GET_LOADINGS_RATES = gql`
  query MyQuery($id: uuid) {
    loading_rates(where: { company_loading_id: { _eq: $id } }) {
      subclass {
        id
        name
      }
      status
      rate
      id
      covertype {
        id
        name
      }
      company_loading {
        id
        loading {
          id
          name
          businessClassByBusinessClass {
            id
            name
          }
        }
      }
    }
  }
`


export const GET_LEVY_RATES = gql`
   query MyQuery($id: uuid) {
  levies_rate(where: {company_levy_id: {_eq: $id}}) {
    subclass {
      id
      name
    }
    status
    rate
    amount
    id
    covertype {
      id
      name
    }
    company_levy {
      id
      levy {
        id
        name
        businessClassByBusinessClass {
          id
          name
        }
      }
    }
  }
}

`
export const GET_LOADINGS = gql`

query myQuery($company_id: uuid) {
  company_loading(where: {company_id: {_eq: $company_id}}) {
    loading {
      id
      name
      description
      businessClassByBusinessClass{
        id
        name
      }
    }
    created_at
id

}
}
`


export const GET_COMP_LOADINGS = gql`

query myQuery($company_id: uuid) {
  company_levies(where: {company_id: {_eq: $company_id}}) {
    levy {
      id
      name
      description
      businessClassByBusinessClass{
        id
        name
      }
    }
    created_at
id

}
}
`

export const GETALLLOADINGS = gql`
  query getAllLoadings {
    loadings {
      description
      id
      name
      status
    }
  }
`

export const GETALLLOADINGRATES = gql`
  query getLoadingrates($loading_id: uuid!) {
    loading_rates(where: { loading_id: { _eq: $loading_id } }) {
      loading {
        id
        name
      }
      description
      rate
      status
      subclass {
        id
        name
      }
      covertype {
        id
        name
      }
      business_class {
        name
        id
      }
    }
  }
`

export const DELETELOADINGRATE = gql`
  mutation deleteLoadingRate($id: uuid!) {
    delete_loading_rates_by_pk(id: $id) {
      id
    }
  }
`
export const GET_BUSINESS_CLASS = gql`
  query getBusinessClass {
    business_classes {
      id
      name
    }
  }
`

export const GETALLCOMMMISSIONS = gql`
  query AllCommissions($id: uuid!) {
    commisions(
      where: {
        _or: [{ company_id: { _eq: $id } }, { company_id: { _is_null: true } }]
      }
    ) {
      description
      created_at
      id
      name
      status
    }
  }
`
export const CHECKREFERENCENUM = gql`
  query MyQuery($ref: String) {
    cash_payment_total: cash_payment_aggregate(
      where: { reference_number: { _eq: $ref }, is_replenished: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    cheque_payment_total: cheque_payment_aggregate(
      where: { reference_number: { _eq: $ref }, is_cleared: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    driver_total: collection_request_aggregate(
      where: {
        replenish_reference: { _eq: $ref }
        is_replenished: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GETALLCOMMMISSIONSRATE = gql`
  query getCommissionRate($commission_id: uuid!) {
    commisions_rates(where: { commision_id: { _eq: $commission_id } }) {
      class_id
      commision_id
      id
      intermediary_type
      rate
      status
      subclass {
        id
        description
        name
      }
      business_class {
        id
        name
        description
      }
      description
      created_at
    }
  }
`

export const GETALLLEVIES = gql`
  query getAllLevies {
    levies {
      created_at
      description
      id
      name
      status
    }
  }
`

export const GETLEVYRATES = gql`
  query getLevyRates($id: uuid!) {
    levies_rate(where: { levy_id: { _eq: $id } }) {
      amount
      business_class {
        id
        name
        description
      }
      covertype {
        id
        description
        name
      }
      description
      id
      levy_id
      rate
      status
      subclass {
        id
        description
        name
      }
    }
  }
`

export const GETALLTAX = gql`
  query getAllTaxes {
    taxes(
      where: { _not: { name: { _in: ["Subscription tax", "NIC Fees"] } } }
    ) {
      created_at
      description
      id
      name
      status
    }
  }
`

export const GETTAXRATES = gql`
  query getTaxRates($id: uuid!, $company_id: uuid) {
    tax_rate(
      where: { tax_id: { _eq: $id }, insurance_company: { _eq: $company_id } }
    ) {
      business_class {
        id
        name
        description
      }
      cover_type_name
      description
      id
      amount
      tax_id
      rate
      status
    }
  }
`

export const GETUSERCOMMISSIONS = gql`
  query getUserCommissions($id: uuid!) {
    user_commission_setup(where: { user_id: { _eq: $id } }) {
      commision {
        id
        name
      }
      settlement_mode
      id
      created_at
    }
  }
`

export const GETTAXPAYABLE = gql`
  query MyQuery($id: uuid!) {
    tax_payable(where: { commission_payable_id: { _eq: $id } }) {
      amount
      id
      rate
      tax {
        id
        name
      }
    }
  }
`

export const DASHBOARDAGGREGATES = gql`
  query dashboardAggregates {
    insurance_companies: insurer_companies_aggregate {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: { intermediary_type: { _eq: "agent" } }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: { intermediary_type: { _eq: "broker" } }
    ) {
      aggregate {
        count
      }
    }
    policies: policy_aggregate(distinct_on: created_at) {
      aggregate {
        count
      }
    }
    policy_holders: view_total_policyholders_count {
      total_policyholders
    }
    total_policy_sold: view_total_policy_sum {
      total_amount_sold
    }
    subscription_rate {
      rate
    }
  }
`

export const CLIENT360AGGREGATES = gql`
  query client360Aggregates($company_id: uuid) {
    insurance_companies: insurer_companies_aggregate(
      where: { id: { _eq: $company_id } }
    ) {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate(
      where: { company_id: { _eq: $company_id } }
    ) {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: {
        intermediary_type: { _eq: "agent" }
        company_id: { _eq: $company_id }
      }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: {
        intermediary_type: { _eq: "broker" }
        company_id: { _eq: $company_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GETTOTALPOLICYPERMONTH = gql`
  query getTotalPolicyPerMonth {
    months_policies: view_total_policy_sum_by_month {
      month
      total_amount
    }
  }
`

export const GETALLINSURANCECOMPANIES = gql`
  query getAllInsuranceCompanies {
    insurer_companies {
      email
      id
      momo_details
      bank_details
      tin_number
      phone
      name
      clientTypeByClientType {
        name
        id
      }
      client_type
      company_person
      created_at
    }
  }
`

export const GETALLINTERMEDIARIESAGENT = gql`
  query getAllIntermediariesAgent($company_id: uuid) {
    intemediary(
      where: {
        intermediary_type: { _eq: "agent" }
        company_id: { _eq: $company_id }
      }
    ) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
  }
`

export const GETALLINTERMEDIARIESBROKER = gql`
  query getAllIntermediariesBroker($company_id: uuid) {
    intemediary(
      where: {
        intermediary_type: { _eq: "broker" }
        company_id: { _eq: $company_id }
      }
    ) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status
      contact_person
      nic_registration_number
      phone_number
      tin_number
      registration_number
    }
  }
`

export const GETALLINSURANCECOMPANYSTAFF = gql`
  query MyQuery($company_id: uuid) {
    company_staff(where: { company_id: { _eq: $company_id } }) {
      active_status
      id
      bank_details
      branch {
        district
        name
        region
      }
      company_role {
        name
      }
      email
      id_number
      id_type
      momo_details
      name
      phone_number_one
      phone_number_two
      staff_number
      tin_number
    }
  }
`

export const GETALLRISKCLASS = gql`
  query getAllriskClass {
    risk_class {
      name
      id
    }
  }
`

export const GETDETAILOFRISKCLASS = gql`
  query getDetailsofRiskClass($id: uuid!) {
    third_party_only_risk_class(where: { risk_class: { id: { _eq: $id } } }) {
      basic_premium
      ecowas_perils
      fee_amount
      total_amount
    }
  }
`

export const GETCOMPANYROLES = gql`
  query getCompanyRoles($id: uuid!) {
    company_role(where: { company_id: { _eq: $id } }) {
      created_at
      description
      id
      name
    }
  }
`

export const GETALLDIVISIONSORWORKGROUPS = gql`
  query getAllDivisionsWOrkGroup($id: uuid!) {
    divisions(where: { insurance_company_id: { _eq: $id } }) {
      description
      id
      name
    }
  }
`

export const GETSTAFFBRANCHESANDDIVISIONS = gql`
  query getStaffBranchesAndDivisions($staff_id: uuid!) {
    staff_branches_divisions(where: { staff_id: { _eq: $staff_id } }) {
      staff_id
      division {
        id
        name
        description
      }
      branch {
        id
        name
        code
      }
      id
    }
  }
`

export const GETSTAFFBRANCHESANDDIVISIONSFORAGENTS = gql`
  query getStaffBranchesAndDivisionsForAgents($agent_id: uuid!) {
    staff_branches_divisions(where: { agent_id: { _eq: $agent_id } }) {
      staff_id
      division {
        id
        name
        description
      }
      branch {
        id
        name
        code
      }
      id
    }
  }
`

export const GETALLBRANCHES = gql`
  query MyQuery {
    branch {
      address
      name
      code
      id
    }
  }
`

export const GETALlUSERCOMMISSIONS = gql`
  query getUserSelectedCommissions($id: uuid!) {
    user_commission_setup(where: { user_id: { _eq: $id } }) {
      id
      settlement_mode
      commision {
        id
        name
      }
    }
  }
`

export const GETCOMPANYSTAFF = gql`
  query MyQuery($company_id: uuid) {
    company_staff(
      where: { company_id: { _eq: $company_id }, active_status: { _eq: true } }
      order_by: { created_at: desc }
    ) {
      phone_number_two
      phone_number_one
      created_at
      tin_number
      staff_number
      name
      momo_details
      is_first_time
      cash_limit
      guarantor_details
      email
      id
      id_number
      id_type
      active_status
      bank_details
      preferred_payment_channel: preferred_payment_method
      division_id
      dob
      role_id
      branch {
        id
        name
      }
    }
  }
`
export const GETAGENTID = gql`
  query MyQuery($code: String) {
    sub_agents(where: { agent_code: { _eq: $code } }) {
      id
      first_name
      last_name
    }
  }
`
export const GETSTAFFID = gql`
  query MyQuery($code: String) {
    company_staff(where: { staff_number: { _eq: $code } }) {
      id
      name
    }
  }
`

export const GETBROKERID = gql`
  query MyQuery($email: String) {
    intemediary(where: { email: { _eq: $email } }) {
      id
      name
    }
  }
`

export const GETCOMPANYAGENTS = gql`
  query MyQuery($company_id: uuid!) {
    intemediary(
      where: {
        intermediary_type: { _eq: "agent" }
        company_id: { _eq: $company_id }
        active_status: { _eq: true }
      }
      order_by: { created_at: desc }
    ) {
      address
      bank_details
      contact_person
      email
      id
      id_number
      preferred_payment_channel: preferred_payment_method
      id_type
      momo_details
      name
      intermediary_type
      nic_registration_number
      phone: phone_number
      registration_number
      tin_number
      branch {
        id
        code
        name
      }
      division_id
      sub_agents {
        role_id
        cash_limit
        agent_code
      }
      created_at
    }
  }
`

export const GETALLFUNDSREQUEST = gql`
  query getAllFundsRequest($company_id: uuid!) {
    view_fund_account_details(
      where: { company_id: { _eq: $company_id } }
      order_by: { created_at: desc }
    ) {
      amount
      parameter_id
      branch_name
      broker_name
      company_role_name
      company_staff_name
      division_name
      funds_request_id
      status
      agent: sub_agent_name
      parameter_type
      agent_balance
      branch_balance
      division_balance
      role_balance
      staff_balance
    }
  }
`

export const GETALLFUNDSREQUESTPENDING = gql`
  query getAllFundsRequest($company_id: uuid) {
    view_fund_account_details(
      where: { status: { _eq: "pending" }, company_id: { _eq: $company_id } }
      order_by: { created_at: desc }
    ) {
      amount
      parameter_id
      branch_name
      broker_balance
      broker_name
      company_role_name
      staff_cash_limit
      agent_cash_limit
      company_staff_name
      division_name
      funds_request_id
      status
      agent: sub_agent_name
      parameter_type
      agent_balance
      created_at
      branch_balance
      division_balance
      role_balance
      broker_cash_limit
      staff_balance
    }
  }
`

export const GETBALANCEOFUSERS = gql`
 query getBalanceofUsers($company_id: uuid) {
  positive_balance_view: view_balance_cashlimit(where: {grouping_id: {_eq: $company_id}}) {
    name
    balance
    cash_limit
    remaining_cash_balance
    amount_used
    record_id
    record_type
  }
}

`

export const GETALLAGENTS = gql`
  query getAllAgentsofCompany($company_id: uuid) {
    agents: view_combined_agent_subagent(
      where: { company_id: { _eq: $company_id } }
    ) {
      first_name
      last_name
      phone_number
      role_id
      nic_registration_number
      is_first_time
      intermediary_type
      balance
      company_id
      agent_id
      agent_code
      active_status
    }
  }
`

export const GETPERMISSIONSFORSPECIFICROLE = gql`
  query getPermissionsForSpecificRole($role_id: uuid!) {
    company_role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        id
        name
      }
      role_id
      selected_actions
    }
  }
`

export const GETPOLICY360 = gql`
  query policy360forCompany($id: uuid) {
    policy(where: { request: { insurer_company: { _eq: $id } } }) {
      policy_number
      id
      request {
        policyHolder: customer {
          first_name
          last_name
        }
        risk_class {
          id
          name
        }
        user_type
        cover_type
        businessClassByBusinessClass {
          id
          name
        }
        end_date
        start_date
        insurance_company: insurerCompanyByInsurerCompany {
          email
          name
          id
          phone
        }
        sub_agent {
          id
          first_name
          last_name
          phone_number
        }
        intemediary {
          id
          intermediary_type
          name
          phone_number
        }
        company_staff {
          id
          phone_number_one
          name
        }
      }
    }
  }
`

export const GETPOLICYTECHNICALTRANSACTION = gql`
  query GETPOLICYTECHNICALTRANSACTION(
    $policy_id: uuid
    $policy_number: String
  ) {
    saved_quoatations: request(
      where: { policies: { id: { _eq: $policy_id } }, is_paid: { _eq: false } }
    ) {
      amended_rated_details
      amount
      benefit_added_details
      businessClassByBusinessClass {
        name
        id
      }
      cover_type
      created_at
      duration
      end_date
      id
      payment_channel
      request_stage
      sub_agent {
        id
        first_name
        email
        last_name
      }
      company_staff {
        email
        id
        phone_number_one
      }
    }
    view_joined_policy_count_companies(
      where: { policy_number: { _eq: $policy_number } }
    ) {
      certificate_number
      created_at
      premium
      created_by
      registration_number_text
      debit_number
      id
      policy_number
      policy_stage
      policy_type
      reciept_number
      status
      total_policies
    }
  }
`

export const GETPOLICYFINANCIALTRANSACTION = gql`
  query MyQuery($policy_id: uuid) {
    taxes_paid: tax_payable(
      where: { commissions_payable: { policy_id: { _eq: $policy_id } } }
    ) {
      amount
      created_at
      id
      rate
      tax {
        id
        name
      }
      commissions_payable {
        commission_amount
        reference_amount
        status
        user_type
      }
      commission_payable_broker {
        commission_amount
        reference_amount
        status
        user_type
      }
    }
    commissions_paid: commissions_payable(
      where: { policy_id: { _eq: $policy_id } }
    ) {
      commision {
        code
        id
        name
      }
      commission_amount
      date_created
      date_updated
      id
      intermediary
      rate
      reference_amount
      status
      total_tax_deductions
      user_type
    }
  }
`

export const POLICYPERFORMANCE = gql`
  query MyQuery($policy_id: uuid) {
    policy_aggregate(
      where: {
        request: {
          policies: { id: { _eq: $policy_id } }
          is_paid: { _eq: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    commissions_payable_aggregate(where: { policy_id: { _eq: $policy_id } }) {
      aggregate {
        sum {
          commission_amount
          total_tax_deductions
        }
      }
    }
  }
`

export const INSURANCECOMPANYDASHBOARD = gql`
  query insuranceCompanyDashboard($id: uuid!) {
    written_premium: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    premium_paid: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    premium_outstanding: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    new_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "new" } }
    ) {
      aggregate {
        count
      }
    }
    lapse_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "lapse" } }
    ) {
      aggregate {
        count
      }
    }
    renewed_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "renewed" } }
    ) {
      aggregate {
        count
      }
    }
    active_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    all_commissions: commissions_payable_aggregate(
      where: {
        insurance_company: { _eq: $id }
        commision: { code: { _neq: "BDD" } }
      }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    commission_paid: commissions_payable_aggregate(
      where: {
        insurance_company: { _eq: $id }
        commision: { code: { _neq: "BDD" } }
        status: { _eq: "paid" }
      }
    ) {
      aggregate {
        sum {
          amount: commission_amount
        }
      }
    }
    outstanding_commissions: commissions_payable_aggregate(
      where: {
        insurance_company: { _eq: $id }
        commision: { code: { _neq: "BDD" } }
        status: { _eq: "pending" }
      }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    total_agents: intemediary_aggregate(
      where: {
        insurer_company: { id: { _eq: $id } }
        intermediary_type: { _eq: "agent" }
      }
    ) {
      aggregate {
        count
      }
    }
    total_brokers: intemediary_aggregate(
      where: {
        insurer_company: { id: { _eq: $id } }
        intermediary_type: { _eq: "broker" }
      }
    ) {
      aggregate {
        count
      }
    }
    total_staff: company_staff_aggregate(
      where: { insurer_company: { id: { _eq: $id } } }
    ) {
      aggregate {
        count
      }
    }
    total_policy_holders: policy_aggregate(
      where: {
        request: {
          insurer_company: { _eq: $id }
          is_paid: { _eq: true }
          request_stage: { _eq: "policy" }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    tax_payable_aggregate: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id } }
    ) {
      aggregate {
        sum {
          amount: total_tax_deductions
        }
      }
    }
  }
`


export const GETALLPAYABLECOMMISSIONS = gql`
  query getAllPayabaleCommissions(
    $id: uuid!
    $status_type: String
    $com_type: String
  ) {
    view_payable_commissions_company(
      where: {
        insurance_company: { _eq: $id }
        status: { _eq: $status_type }
        commission_type: { _eq: $com_type }
      }
      order_by: { created_at: desc }
    ) {
      payable_commission_id
      account_details
      agent_first_name
      premium
      policy_number
      agent_last_name
      staff_name
      user_type
      created_at
      commission_name
      sub_class_id
      risk_id
      risk_or_sub_class_name
      reference_amount
      gross_amount
      rate
      net_amount
      total_tax_deductions
      status
      business_class_name
    }
  }
`

export const GETREPORTDATA = gql`
  query MyQuery($startDate: timestamp, $endDate: timestamp) {
    policy(
      where: { created_at: { _gte: $startDate, _lte: $endDate } }
      order_by: { created_at: desc }
    ) {
      certificate_number
      created_at
      debit_number
      documents: documentsByPolicyId {
        document_type
        id
        name
      }
      policy_number
      id
      policy_stage
      reciept_number
      status
      quotation: request {
        amended_rated_details
        premium: amount
        benefit_added_details
        business_class: businessClassByBusinessClass {
          name
          id
          description
        }
        cover_type
        created_at
        currency: currencyByCurrency {
          name
          id
        }
        customer {
          created_at
          customer_type
          email
          first_name
          id
          last_name
          phone_number
        }
        duration
        end_date
        id
        is_paid
        payment_channel
        quotation_number: request_id
        request_stage
        start_date
        vehicle_details
        risk_class {
          id
          name
        }
        user_type
        sub_agent {
          id
          first_name
          agent_code
          email
          last_name
        }
        insurance_company: insurerCompanyByInsurerCompany {
          address
          bank_details
          client_type
          email
          id
          name
          phone
        }
        intermediary_added: intemediary {
          email
          id
          id_number
          intermediary_type
          intermediary_type_code
          name
          nic_registration_number
          phone_number
          sub_agents {
            agent_code
          }
        }
      }
    }
  }
`

export const COMMISSIONAGGREGATE = gql`
  query MyQuery($id: uuid!, $code: [String!]) {
    Motor_policies: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        commission_code: { _neq: "BDD" }
      }
    ) {
      aggregate {
        sum {
          amount
          commission_amount
        }
      }
    }
    Home_owners: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Home Owners" }
        commission_code: { _neq: "BDD" }
      }
    ) {
      aggregate {
        sum {
          amount
          commission_amount
        }
      }
    }
    Motor_comprehensive: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Comprehensive" }
        commission_code: { _neq: "BDD" }
      }
    ) {
      aggregate {
        sum {
          amount
          commission_amount
        }
      }
    }
    Motor_third_party: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Third Party" }
        commission_code: { _neq: "BDD" }
      }
    ) {
      aggregate {
        sum {
          amount
          commission_amount
        }
      }
    }
    Motor_third_party_fire: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Third Party Fire and Theft" }
        commission_code: { _neq: "BDD" }
      }
    ) {
      aggregate {
        sum {
          amount
          commission_amount
        }
      }
    }
    all_commissions: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    all_types_of_commissios: view_type_of_commissions_generated_company(
      where: { insurance_company: { _eq: $id }, code: { _nin: $code } }
    ) {
      commission_id
      commission_name
      code
      total_amount
    }
  }
`

export const GETPAYMENTREQUISITIONDetail = gql`
  query getAllPaymentRequisition($id: uuid!) {
    view_payment_requistion_details(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
        commission_code: { _eq: "BDD" }
      }
      order_by: { created_at: desc }
    ) {
      account_details
      created_at
      momo_details
      policy_number
      risk_id
      business_class_name
      sub_class_name
      id
      insurance_company_id
      payee_details
      provider_transaction_id
      amount_requisitioned
      payee_id
      payee_name
      payee_number
      payee_type
      payment_stage
      policy_id
      rate
      reference
      reference_amount
      transaction_type: transaction_name
    }
    payment_requisition(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
      order_by: { created_at: desc }
    ) {
      amount_requisitioned
    }
  }
`

export const SEARCHREPORTS = gql`
  query GetAllReport($searchText: String, $jsonFilter: jsonb) {
    drivers_aggregate(where: { account_status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
    drivers(
      order_by: { created_at: desc }
      where: {
        account_status: { _eq: "active" }
        _or: [
          { account_status: { _ilike: $searchText } }
          { momo_wallet_details: { _is_null: false, _contains: $jsonFilter } }
          { bank_details: { _is_null: false, _contains: $jsonFilter } }
          { company_staff: { name: { _ilike: $searchText } } }
          { company_staff: { phone_number_one: { _ilike: $searchText } } }
          { driver_code: { _ilike: $searchText } }
          { address: { _ilike: $searchText } }
          { email: { _ilike: $searchText } }
          { full_name: { _ilike: $searchText } }
          { gender: { _ilike: $searchText } }
          { id_card_type: { _ilike: $searchText } }
          { id_number: { _ilike: $searchText } }
          { phone_number_1: { _ilike: $searchText } }
          { phone_number_2: { _ilike: $searchText } }
        ]
      }
    ) {
      account_status
      address
      id
      id_card_type
      balance
      bank_details
      total_collections: collection_requests_aggregate(
        where: { status: { _eq: "success" } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
      created_at
      driver_code
      driver_beneficiaries {
        first_name
        surname
        relationship
        share_percentage
      }
      total_invest2insure_payments: driver_invest2insure_payments_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      total_invest2insure_payments_balance: driver_invest2insure_payments_aggregate(
        distinct_on: [policy_id]
        order_by: [{ policy_id: asc }, { datecreated: desc }]
      ) {
        aggregate {
          sum {
            premium_balance
          }
        }
      }
      email
      first_name
      full_name
      gender
      id_number
      momo_wallet_details
      phone_number_1
      phone_number_2
      surname
      total_withdrawals: driver_withdrawal_requests_aggregate(
        where: { status: { _eq: "success" } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
      sub_agent {
        first_name
        id
        phone_number
        last_name
      }
      company_staff {
        id
        name
        phone_number_one
      }
    }
  }
`
export const SEARCHREPORTSAMOUNT = gql`
  query GetAllReport($searchAmount: numeric) {
    drivers_aggregate(where: { account_status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
    drivers(
      order_by: { created_at: desc }
      where: {
        account_status: { _eq: "active" }
        _or: [{ balance: { _eq: $searchAmount } }]
      }
    ) {
      account_status
      address
      id
      id_card_type
      balance
      bank_details
      total_collections: collection_requests_aggregate(
        where: { status: { _eq: "success" } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
      created_at
      driver_code
      driver_beneficiaries {
        first_name
        surname
        relationship
        share_percentage
      }
      total_invest2insure_payments: driver_invest2insure_payments_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      total_invest2insure_payments_balance: driver_invest2insure_payments_aggregate(
        distinct_on: [policy_id]
        order_by: [{ policy_id: asc }, { datecreated: desc }]
      ) {
        aggregate {
          sum {
            premium_balance
          }
        }
      }
      email
      first_name
      full_name
      gender
      id_number
      momo_wallet_details
      phone_number_1
      phone_number_2
      surname
      total_withdrawals: driver_withdrawal_requests_aggregate(
        where: { status: { _eq: "success" } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
      sub_agent {
        first_name
        id
        phone_number
        last_name
      }
      company_staff {
        id
        name
        phone_number_one
      }
    }
  }
`

export const GETCOMMISSIONSEARCH = gql`
  query MyQuery($searchText: String, $jsonFilter: jsonb) {
    collection_request(
      order_by: { created_at: desc }
      where: {
        status: { _eq: "success" }
        _or: [
          { reference_number: { _ilike: $searchText } }
          # { amount: { _eq: $searchAmount } }
          # { driver_current_balance: { _eq: $searchAmount } }
          { driver: { full_name: { _ilike: $searchText } } }
          { driver: { first_name: { _ilike: $searchText } } }
          { driver: { first_name: { _ilike: $searchText } } }
          { driver: { driver_code: { _ilike: $searchText } } }
          { driver: { email: { _ilike: $searchText } } }
          { driver: { id_card_type: { _ilike: $searchText } } }
          { driver: { gender: { _ilike: $searchText } } }
          { driver: { address: { _ilike: $searchText } } }
          { driver: { id_number: { _ilike: $searchText } } }
          { driver: { phone_number_1: { _ilike: $searchText } } }
          { sub_agent: { first_name: { _ilike: $searchText } } }
          { sub_agent: { last_name: { _ilike: $searchText } } }
          { company_staff: { name: { _ilike: $searchText } } }
          {
            driver: {
              momo_wallet_details: { _is_null: false, _contains: $jsonFilter }
            }
          }
          { payment_mode: { _ilike: $searchText } }
        ]
      }
    ) {
      amount
      created_at
      created_by
      payment_mode
      id
      reference_number
      status
      driver {
        account_status
        address
        balance
        driver_code
        bank_details
        consent
        dob
        email
        gender
        id_card_type
        id
        id_number
        momo_wallet_details
        phone_number_1
        phone_number_2
        full_name
      }
      company_staff {
        id
        name
        phone_number_one
      }
      sub_agent {
        first_name
        id
        phone_number
        last_name
      }
      driver_current_balance
    }
    collection_request_aggregate(where: { status: { _eq: "success" } }) {
      aggregate {
        count
      }
    }
  }
`
export const GETCOMMISSIONSEARCHAMOUNT = gql`
  query MyQuery($searchAmount: numeric) {
    collection_request(
      order_by: { created_at: desc }
      where: {
        status: { _eq: "success" }
        _or: [
          { amount: { _eq: $searchAmount } }
          { driver_current_balance: { _eq: $searchAmount } }
        ]
      }
    ) {
      amount
      created_at
      created_by
      payment_mode
      id
      reference_number
      status
      driver {
        account_status
        address
        balance
        bank_details
        consent
        dob
        email
        gender
        id_card_type
        id
        id_number
        momo_wallet_details
        phone_number_1
        phone_number_2
        full_name
      }
      company_staff {
        id
        name
        phone_number_one
      }
      sub_agent {
        first_name
        id
        phone_number
        last_name
      }
      driver_current_balance
    }
    collection_request_aggregate(where: { status: { _eq: "success" } }) {
      aggregate {
        count
      }
    }
  }
`

export const GETPAYMENTREQUISITIONDetail2 = gql`
  query getAllPaymentRequisition($id: uuid!) {
    view_payment_requistion_details(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
        commission_code: { _neq: "BDD" }
      }
      order_by: { created_at: desc }
    ) {
      account_details
      created_at
      momo_details
      policy_number
      risk_id
      business_class_name
      sub_class_name
      id
      insurance_company_id
      payee_details
      provider_transaction_id
      amount_requisitioned
      payee_id
      payee_name
      payee_number
      payee_type
      payment_stage
      policy_id
      rate
      reference
      reference_amount
      transaction_type: transaction_name
    }
    payment_requisition(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
      order_by: { created_at: desc }
    ) {
      amount_requisitioned
    }
  }
`

export const GETPOLICYDETAILTEN = gql`
  query getPolicyDetailsTen {
    policy(where: {}, order_by: { created_at: desc }, limit: 10) {
      id
      request {
        id
        start_date
        end_date
        customer {
          first_name
          last_name
        }
        business_class: businessClassByBusinessClass {
          name
          id
        }
        sub_class: risk_class {
          id
          name
        }
      }
      created_at
    }
  }
`
export const GETPOLICYDETAIL = gql`
  query getPolicyDetails($policy_number: String) {
    policy(where: { policy_number: { _ilike: $policy_number } }) {
      id
      request {
        id
        start_date
        end_date
        customer {
          first_name
          last_name
        }
        business_class: businessClassByBusinessClass {
          name
          id
        }
        sub_class: risk_class {
          id
          name
        }
      }
    }
  }
`

export const GETPAYMENTOFPOLICY = gql`
  query getPaymentsOfpolicy($policy_id: uuid!) {
    payment_history: approved_payment_requisitions(
      where: { policy_id: { _eq: $policy_id } }
    ) {
      amount
      approved_by
      created_at
      id
      status
      transaction_number
      transaction_type
      description
      payment_requisition {
        payee_type
        payee_id
        reference
        reference_amount
        rate
        id
        created_by
      }
    }
  }
`

export const GETALLBANKS = gql`
  query getALlBanks {
    bank {
      code
      id
      name
    }
  }
`

export const DRIVERAGGREGATE = gql`
  query MyQuery {
    active_drivers: drivers_aggregate(
      where: { account_status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    inactive_drivers: drivers_aggregate(
      where: { account_status: { _in: ["inactive", "incomplete"] } }
    ) {
      aggregate {
        count
      }
    }
    total_drivers: drivers_aggregate {
      aggregate {
        count
      }
    }
    total_collections: collection_request_aggregate(
      where: { status: { _eq: "success" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    total_withdrawals: driver_withdrawal_request_aggregate(
      where: { status: { _eq: "success" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    total_drivers_policies: request_aggregate(
      where: {
        payment_channel: { _eq: "invest2insure" }
        is_paid: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
export const GETDATAWITHDRAWAL = gql`
  query getAllWithdrawals($searchText: String, $jsonFilter: jsonb) {
    driver_withdrawal_request(
      order_by: { created_at: desc }
      where: {
        status: { _eq: "success" }
        _or: [
          { reference_number: { _ilike: $searchText } }
          { driver: { full_name: { _ilike: $searchText } } }
          { driver: { email: { _ilike: $searchText } } }
          { driver: { first_name: { _ilike: $searchText } } }
          { driver: { surname: { _ilike: $searchText } } }
          { driver: { gender: { _ilike: $searchText } } }
          { driver: { id_card_type: { _ilike: $searchText } } }
          { driver: { address: { _ilike: $searchText } } }
          { driver: { id_number: { _ilike: $searchText } } }
          { driver: { phone_number_1: { _ilike: $searchText } } }
          {
            driver: {
              momo_wallet_details: { _is_null: false, _contains: $jsonFilter }
            }
          }
          { company_staff: { name: { _ilike: $searchText } } }
          { company_staff: { phone_number_one: { _ilike: $searchText } } }
        ]
      }
    ) {
      amount
      created_at
      created_by
      status
      reference_number
      id
      otp_code
      driver {
        account_status
        address
        balance
        bank_details
        created_at
        consent
        dob
        email
        full_name
        gender
        id
        id_card_type
        id_number
        momo_wallet_details
        phone_number_1
        phone_number_2
      }
      staff_details: company_staff {
        id
        phone_number_one
        name
      }
      agent_details: sub_agent {
        first_name
        id
        phone_number
        last_name
      }
      driver_current_balance
    }
  }
`
export const GETINVESTTOINSUREPOLICIES = gql`
  query MyQuery($company_id: uuid, $searchText: String) {
    policy(
      order_by: { created_at: desc }
      where: {
        _or: [
          { policy_number: { _ilike: $searchText } }
          { request: { amount: { _ilike: $searchText } } }
          { request: { customer: { first_name: { _ilike: $searchText } } } }
          { request: { customer: { last_name: { _ilike: $searchText } } } }
          {
            request: {
              businessClassByBusinessClass: { name: { _ilike: $searchText } }
            }
          }
          { request: { risk_class: { name: { _ilike: $searchText } } } }
          { request: { registration_number_text: { _ilike: $searchText } } }
          {
            driver_invest2insure_payments: {
              driver: { full_name: { _ilike: $searchText } }
            }
          }
          {
            driver_invest2insure_payments: {
              company_staff: { name: { _ilike: $searchText } }
            }
          }
          {
            driver_invest2insure_payments: {
              company_staff: { phone_number_one: { _ilike: $searchText } }
            }
          }
          {
            driver_invest2insure_payments: {
              driver: { phone_number_1: { _ilike: $searchText } }
            }
          }
        ]
        created_by: { _eq: $company_id }
        request: {
          payment_channel: { _eq: "invest2insure" }
          is_paid: { _eq: true }
          request_stage: { _eq: "policy" }
        }
      }
    ) {
      policy_number
      datecreated: created_at
      request {
        registration_number_text
        policyholder: customer {
          id
          last_name
          first_name
        }
        start_date
        end_date
        premium_amount: amount
        businessClassByBusinessClass {
          id
          name
        }
        sub_class: risk_class {
          id
          name
        }
      }
      driver_invest2insure_payments(limit: 1) {
        amount
        driver {
          full_name
          driver_code
          id
          phone_number_1
        }
        sub_agent {
          id
          first_name
          last_name
          phone_number
        }
        company_staff {
          id
          name
          phone_number_one
        }
      }
    }
  }
`
export const QUICKSEARCH = gql`
  query MyQuery($id: uuid) {
    intemediary(where: { id: { _eq: $id } }) {
      name
    }
  }
`

export const DRIVERARREARS = gql`
  query driverArrears($id: uuid) {
    driver_payments_schedule_aggregate(where: { driver_id: { _eq: $id } }) {
      aggregate {
        sum {
          arrears
        }
      }
    }
  }
`

export const GETALLSAVEDQUOTATIONS = gql`
  query MyQuery($id: uuid) {
    request(
      where: {
        is_paid: { _eq: false }
        request_stage: { _eq: "quote" }
        insurer_company: { _eq: $id }
      }
    ) {
      amended_rated_details
      amount
      request_id
      businessClassByBusinessClass {
        name
      }
      payment_channel
      car_number_type
      cover_type
      proposer: customer {
        first_name
        last_name
        phone_number
      }
      registration_number_text
      duration
      end_date
      start_date
      sub_agent {
        first_name
        id
        last_name
      }
      company_staff {
        id
        email
        name
        phone_number: phone_number_one
      }
      vehicle_details
      vehicle_details_name
      user_type
      sub_class: risk_class {
        name
        id
      }
    }
  }
`

export const GETALLNEWPOLICIES = gql`
  query MyQuery($stage: String, $id: uuid) {
    policy(
      where: {
        policy_stage: { _eq: $stage }
        request: { insurer_company: { _eq: $id } }
      }
    ) {
      policy_number
      policy_stage
      certificate_number
      request {
        payment_channel
        vehicle_reg_no: registration_number_text
        client_details: customer {
          first_name
          last_name
          phone_number
        }
        vehicle_details_metadata: vehicle_details_name
        end_date
        start_date
        user_type
        sub_agent {
          id
          last_name
          first_name
          phone_number
        }
        company_staff {
          id
          phone_number: phone_number_one
          name
        }
        amount
        cover_type
        benefit_added_details
        amended_rated_details
        vehicle_details
      }
      debit_number
      reciept_number
      status
    }
  }
`

export const GETALLRENEWALPOLICIES = gql`
  query MyQuery($stage: String) {
    policy(where: { policy_stage: { _eq: $stage } }) {
      policy_number
      policy_stage
      certificate_number
      request {
        vehicle_reg_no: registration_number_text
        client_details: customer {
          first_name
          last_name
          phone_number
        }
        vehicle_details_metadata: vehicle_details_name
        end_date
        start_date
        user_type
        sub_agent {
          id
          last_name
          first_name
          phone_number
        }
        company_staff {
          id
          phone_number: phone_number_one
          name
        }
        amount
        cover_type
        benefit_added_details
        amended_rated_details
        vehicle_details
      }
      debit_number
      reciept_number
      status
    }
  }
`

export const GETALLLAPSEDPOLICIES = gql`
  query MyQuery($stage: String) {
    policy(where: { policy_stage: { _eq: $stage } }) {
      policy_number
      policy_stage
      certificate_number
      request {
        vehicle_reg_no: registration_number_text
        client_details: customer {
          first_name
          last_name
          phone_number
        }
        vehicle_details_metadata: vehicle_details_name
        end_date
        start_date
        user_type
        sub_agent {
          id
          last_name
          first_name
          phone_number
        }
        company_staff {
          id
          phone_number: phone_number_one
          name
        }
        amount
        cover_type
        benefit_added_details
        amended_rated_details
        vehicle_details
      }
      debit_number
      reciept_number
      status
    }
  }
`

export const GETALLPAYABLETAXES = gql`
  query MyQuery($company_id: uuid) {
    tax_payable(
      order_by: { created_at: asc }
      where: {
        commissions_payable: { insurance_company: { _eq: $company_id } }
      }
    ) {
      amount
      rate
      id
      created_at
      commissions_payable {
        policy {
          policy_number
          request {
            cover_type
            user_type
            amount
            businessClassByBusinessClass {
              name
              id
            }
            risk_class {
              id
              name
            }
            sub_agent {
              id
              last_name
              phone_number
              first_name
            }
            company_staff {
              id
              phone_number_one
              name
            }
            company_tin: insurerCompanyByInsurerCompany {
              tin_number
            }
          }
        }
        total_tax_deductions
        commission_amount
      }
      tax {
        id
        name
      }
    }
  }
`

export const GETPAYMENTAPPROVALS = gql`
  query getAllPaymentRequisitionStatus($id: uuid!) {
    view_payment_requistion_details(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _neq: "pending" }
        insurance_company_id: { _eq: $id }
        commission_code: { _neq: "BDD" }
      }
      order_by: { created_at: desc }
    ) {
      account_details
      created_at
      policy_number
      risk_id
      business_class_name
      sub_class_name
      id
      insurance_company_id
      payee_details
      provider_transaction_id
      amount_requisitioned
      payee_id
      payee_name
      payee_number
      payee_type
      payment_stage
      policy_id
      status
      rate
      reference
      reference_amount
      transaction_type: transaction_name
    }
    payment_requisition(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
      order_by: { created_at: desc }
    ) {
      amount_requisitioned
    }
  }
`



export const GETPAYMENTAPPROVALSI2I = gql`
  query getAllPaymentRequisitionStatus($id: uuid!) {
    view_payment_requistion_details(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _neq: "pending" }
        insurance_company_id: { _eq: $id }
        commission_code: { _eq: "BDD" }
      }
      order_by: { created_at: desc }
    ) {
      account_details
      created_at
      policy_number
      risk_id
      business_class_name
      sub_class_name
      id
      insurance_company_id
      payee_details
      provider_transaction_id
      amount_requisitioned
      payee_id
      payee_name
      payee_number
      payee_type
      payment_stage
      policy_id
      status
      rate
      reference
      reference_amount
      transaction_type: transaction_name
    }
    payment_requisition(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
      order_by: { created_at: desc }
    ) {
      amount_requisitioned
    }
  }
`
// export const GETPAYMENTAPPROVALSlll = gql`
//   query MyQuery($id: uuid) {
//     approved_payment_requisitions(
//       where: { insurance_company_id: { _eq: $id } }
//     ) {
//       amount
//       created_at
//       description
//       id
//       status
//       transaction_number
//       transaction_type
//       payment_requisition {
//         amount_requisitioned
//         created_at
//         initiation_type
//         payee_id
//         payee_type
//         rate
//         reference_amount
//       }
//       policy {
//         policy_number
//         status
//         debit_number
//         certificate_number
//         request {
//           amount
//           amended_rated_details
//           benefit_added_details
//           company_staff {
//             id
//             name
//             phone_number_one
//           }
//           cover_type
//           businessClassByBusinessClass {
//             id
//             name
//           }
//           risk_class {
//             id
//             name
//           }
//           sub_agent {
//             first_name
//             id
//             last_name
//             phone_number
//           }
//         }
//       }
//     }
//   }
// `

export const GETPAYMENTAPPROVALS2 = gql`
  query MyQuery($id: uuid, $com_code: String) {
    approved_payment_requisitions(
      where: {
        insurance_company_id: { _eq: $id }
        payment_requisition: {
          commissions_payable: { commision: { code: { _eq: $com_code } } }
        }
      }
    ) {
      amount
      created_at
      description
      id
      status
      transaction_number
      transaction_type
      payment_requisition {
        amount_requisitioned
        created_at
        initiation_type
        payee_id
        payee_type
        rate
        reference_amount
      }
      policy {
        policy_number
        status
        debit_number
        certificate_number
        request {
          amount
          amended_rated_details
          benefit_added_details
          company_staff {
            id
            name
            phone_number_one
          }
          cover_type
          businessClassByBusinessClass {
            id
            name
          }
          risk_class {
            id
            name
          }
          sub_agent {
            first_name
            id
            last_name
            phone_number
          }
        }
      }
    }
  }
`

export const POLICYWITHOUTSTICKERS = gql`
  query MyQuery($insurer_company: uuid) {
  policy(
    where: {
      _and: [
        {
          _or: [
            { _not: { documentsByPolicyId: { document_type: { _eq: "sticker" } } } }
            { documentsByPolicyId: { name: { _is_null: true } } }
          ]
        }
        { request: { insurer_company: { _eq: $insurer_company } } }
      ]
    }
    order_by: { request: { created_at: desc } }
  ) {
    id
    policy_number
    request {
      registration_number: registration_number_text
      amount
      insurer_company
      business_class: businessClassByBusinessClass {
        name
      }
      cover_type
      customer_detail: customer {
        first_name
        last_name
        phone_number
      }
      created_date: created_at
      company_staff {
        id
        name
        phone_number: phone_number_one
      }
      agent_details: sub_agent {
        first_name
        last_name
        phone_number
      }
      insurer_company
      user_type
    }
  }
}

`

export const POLICYSTICKERDETAILS = gql`
 query MyQuery($id: uuid) {
  policy(where: {id: {_eq: $id}}) {
    policy_sticker_creation_metadata
  }
  documents(where: {document_type: {_eq: "sticker"}, policy_id: {_eq: $id}, name: {_is_null: true}}) {
    error_logs
  }
}


`

export const Customers = gql`
  query MyQuery(
    $company_id: uuid
    $start_date: timestamp
    $end_date: timestamp
  ) {
    customers(
      where: {
        owner: { _eq: $company_id }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      customer_type
      email
      first_name
      ghana_card
      id
      last_name
      phone_number
      date: created_at
    }
  }
`

export const GETSUBSCRIPTION = gql`
  query MyQuery(
    $company_id: uuid
    $start_date: timestamp
    $end_date: timestamp
  ) {
    subscription_payment(
      where: {
        insurance_company_id: { _eq: $company_id }
        datecreated: { _gte: $start_date, _lte: $end_date }
        status: { _eq: "success" }
      }
    ) {
      amount
      date: datecreated
      reference_number
      status
      policy {
        policy_number
        request {
          amount
          businessClassByBusinessClass {
            id
            name
          }
          cover_type
        }
      }
    }
  }
`

// export const GETTAXREPORT = gql`
//   query MyQuery(
//     $company_id: uuid
//     $start_date: timestamp
//     $end_date: timestamp
//   ) {
//     tax_payable(
//       where: {
//         commissions_payable: { insurance_company: { _eq: $company_id } }
//         created_at: { _gte: $start_date, _lte: $end_date }
//       }
//     ) {
//       amount
//       date: created_at
//       rate
//       id
//       tax {
//         id
//         name
//       }
//       commissions_payable {
//         commission_amount
//         rate
//         reference_amount
//         status
//         commision {
//           id
//           name
//         }
//       }
//     }
//   }
// `

// export const COMMISSIONREPORT = gql`
//   query MyQuery(
//     $company_id: uuid
//     $start_date: timestamp
//     $end_date: timestamp
//   ) {
//     view_payment_requistion_details(
//       where: {
//         transaction_category: { _eq: "commission" }
//         insurance_company_id: { _eq: $company_id }
//         created_at: { _gte: $start_date, _lte: $end_date }
//       }
//     ) {
//       account_details
//       created_by
//       payee_details
//       payee_name
//       payee_number
//       payee_type
//       payment_stage
//       provider_transaction_id
//       rate
//       date: created_at
//       reference
//       reference_amount
//       status
//       transaction_name
//       transaction_category
//       transaction_type_id
//       policy_id
//     }
//   }
// `

// export const POLICYREPORT = gql`
//   query geyPolicyDetails(
//     $id: uuid
//     $start_date: timestamp
//     $end_date: timestamp
//   ) {
//     policy(
//       where: {
//         request: { insurer_company: { _eq: $id } }
//         created_at: { _gte: $start_date, _lte: $end_date }
//       }
//     ) {
//       certificate_number
//       debit_number
//       date: created_at
//       policy_number
//       request {
//         amount
//         payment_channel
//         user_type
//         sub_agent {
//           email
//           first_name
//           id
//           last_name
//         }
//         businessClassByBusinessClass {
//           id
//           name
//         }
//         company_staff {
//           id
//           name
//           email
//         }
//         cover_type
//       }
//     }
//   }

// `;

export const GETTAXREPORT = gql`
  query MyQuery(
    $company_id: uuid
    $start_date: timestamp
    $end_date: timestamp
  ) {
    tax_payable(
      where: {
        commissions_payable: { insurance_company: { _eq: $company_id } }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      amount
      date: created_at
      rate
      id
      tax {
        id
        name
      }
      commissions_payable {
        commission_amount
        rate
        reference_amount
        status
        commision {
          id
          name
        }
      }
    }
  }
`

export const COMMISSIONREPORT = gql`
  query MyQuery(
    $company_id: uuid
    $start_date: timestamp
    $end_date: timestamp
  ) {
    view_payment_requistion_details(
      where: {
        transaction_category: { _eq: "commission" }
        insurance_company_id: { _eq: $company_id }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      account_details
      created_by
      payee_details
      payee_name
      payee_number
      payee_type
      payment_stage
      provider_transaction_id
      rate
      date: created_at
      reference
      reference_amount
      status
      transaction_name
      transaction_category
      transaction_type_id
      policy_id
    }
  }
`

export const POLICYREPORT = gql`
  query geyPolicyDetails(
    $id: uuid
    $start_date: timestamp
    $end_date: timestamp
  ) {
    policy(
      where: {
        request: { insurer_company: { _eq: $id } }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      certificate_number
      debit_number
      date: created_at
      policy_number
      request {
        amount
        duration
        end_date
        start_date
        registration_number_text
        payment_channel
        user_type
        sub_agent {
          email
          first_name
          id
          last_name
        }
        business_class: businessClassByBusinessClass {
          id
          name
        }
        company_staff {
          id
          name
          email
        }
        cover_type
        customer {
          email
          first_name
          id
          last_name
          phone_number
        }
        is_paid
        risk_class {
          id
          name
        }
        vehicle_details_name
        amended_rated_details
        benefit_added_details
        vehicle_details
      }
      commissions_payables(where: { status: { _eq: "paid" } }) {
        commission_amount
        status
        reference_amount
        rate
        total_tax_deductions
        commision {
          id
          name
        }
        tax_payables {
          id
          rate
          tax {
            id
            name
          }
        }
      }
      reciept_number
      status
      subscription_payments {
        amount
        id
        reference_number
        status
      }
    }
  }
`

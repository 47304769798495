import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"

import { useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
  GET_COMP_LOADINGS,

} from "../../../GraphQl/queries"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { DriverModal } from "../../../components/modal/DriverModal"
import UpdateRateAndAdditionalBenefits from "../discountRates/update"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"



type CompanyLoadings = {
  levy: {
    id: string;
    name: string;
    description: string;
    businessClassByBusinessClass: {
      id: string;
      name: string;
      __typename: string;
    };
    __typename: string;
  };
  created_at: string;
  id: string;
  __typename: string;
};

const PolicyLevy = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [open, setOpen] = useState(false)

  const {
    companyId,

    user: stateUser,
  } = useSelector((state: RootState) => state.auth)





  const { data, loading, refetch } = useQuery<{
    company_levies: CompanyLoadings[]
  }>(GET_COMP_LOADINGS, {
    variables: {
      company_id: companyId,
    },
  })

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const filterData = (filter: CompanyLoadings) => {
    const isTextMatch =
      filter?.levy?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.levy?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  // Filtered and paginated data for the current page
  //------------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])


  const totalItems = data?.company_levies?.filter(filterData)
  // const paginatedData = totalItems?.slice(startIndex, endIndex)


  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)



  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  return (
    <>
      <CustomHeader title="Levies & Charges" />
      <DriverModal
        title={<p className=" text-gray-800 text-[1.2rem]">Update Rate</p>}
        open={open}
        size={400}
        focus="message"
        onClose={() => setOpen(false)}
        children={
          <UpdateRateAndAdditionalBenefits
            Id={""}
            value={0}
            type="Rate"
            onConfirm={() => refetch()}
            onClose={() => setOpen(false)}
          />
        }
      />
      <div className="w-full ">
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              List of All Levies
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["no", "Business Class", "Levy", "Description", ""].map(
                      (head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2 text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.levy?.businessClassByBusinessClass?.name || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.levy?.name || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.levy?.description || "N/A"}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "loading",
                                  JSON.stringify({
                                    id: item?.id,
                                    bus_name: item?.levy?.businessClassByBusinessClass?.name,
                                    bus_id: item?.levy?.businessClassByBusinessClass?.id,
                                    name: item?.levy
                                      ?.name,
                                    description: item?.levy?.description,
                                  })
                                )
                                history.push(
                                  "/dashboard/setup/manage-policy-levies"
                                )
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage levies
                            </Button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedData && paginatedData?.length !== 0 && !loading && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <WhitePaginateComponent
                totalPages={totalItems?.length!}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default PolicyLevy

import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../components/headers/CustomHeader"
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import moment from "moment"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { toast } from "react-toastify"
import { DEFAULTPRIMARYCOLOR } from "../../util"

import { DetailDrawer } from "../../components"
import CollectionDetail from "../../components/Details/collection-detail copy"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { RiAdminLine } from "react-icons/ri"
import { DriverAggreateType } from "../../types/query"
import { DRIVERAGGREGATE, GETCOMMISSIONSEARCH } from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import { getColorCode } from "../../colorCodes"
import { useDebounceSearch } from "../../hooks/useDebounce"
import { parse } from "path"
import { useSearchCollections } from "../../hooks/useSearch"
import PerPageAndPagination from "../../components/paginations/perpageAndPagination"

type ResponseType = {
  amount: number
 
  created_at: string
  created_by: string
  payment_mode: string
  driver_current_balance: number
  id: string
  reference_number: string
  status: string
  company_staff: {
    id: string
    name: string
    phone_number_one: string
  }
  sub_agent: {
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  driver: {
    account_status: string
    address: string
    driver_code:string
    balance: number
    bank_details: null
    consent: false
    dob: null
    email: string
    gender: string
    id_card_type: string
    id: string
    id_number: string
    momo_wallet_details: {
      network: string
      number: string
    }
    phone_number_1: string
    phone_number_2: string
    full_name: string
  }
  agent_staff_details: {
    id: string
    phone_number: string
    first_name: string
    last_name: string
  }
}
const Invest2InsureCollection = () => {
  const [searchText, setSearchText] = useState("")
  const [data, setData] = useState<ResponseType[]>([])

  const [loading, setLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState<ResponseType>()
  const [open, setOpen] = useState<boolean>(false)
  const [totalPageSize, setTotalPageSize] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const {

    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  // const search = useDebounceSearch<string>(searchText)
  const search = useDebounceSearch<string>(searchText)
  // console.log(search)
  const {
    loading: loadingData,
    error: errData,
    data: collectionData,
  } = useSearchCollections(search)

  const amount = parseFloat(search)


  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
    setCurrentPage(1)
    setSearchText("")
  }

  const { data: totalcollection, loading: load } =
    useQuery<DriverAggreateType>(DRIVERAGGREGATE)
  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(0)
    setStartDate("")
    setEndDate("")
  }

  const [activePage, setActivePage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  // console.log(activePage)

  useEffect(() => {
    const fetchData = async () => {
      setData([])
      setLoading(true)
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/get-all-collections?page=${
            activePage + 1
          }&limit=${pageSize}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const req = await res.json()
       
        

        if (res?.ok) {
          if (req?.error) {
            toast.error(req?.data[0])
          } else {
            // console.log(req?.data)
            setData(req?.data)
            setTotalPageSize(req?.total_records)
          }
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        toast.error(error?.message ?? "Something went wrong. Try again!")
      }
    }
    fetchData()
  }, [activePage, pageSize, token])


  const filterData = (filter: ResponseType) => {
    const formattedDate = moment(filter?.created_at).format("LLL").toLowerCase()
    const searchTextLower = searchText?.toLowerCase()

    const isTextMatch =
      filter?.reference_number?.toLowerCase()?.includes(searchTextLower) ||
      filter?.amount.toString().toLowerCase().includes(searchTextLower) ||
      filter?.driver_current_balance
        .toString()
        .toLowerCase()
        .includes(searchTextLower) ||
      filter?.driver?.full_name?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.email?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.gender?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.id_card_type?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.address?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.id_number?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver?.phone_number_1
        ?.toLowerCase()
        ?.includes(searchTextLower) ||
      filter?.driver?.momo_wallet_details?.network
        ?.toLowerCase()
        ?.includes(searchTextLower) ||
      filter?.sub_agent?.first_name?.toLowerCase()?.includes(searchTextLower) ||
      filter?.sub_agent?.last_name?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver.momo_wallet_details.network
        ?.toLowerCase()
        ?.includes(searchTextLower) ||
      filter?.company_staff?.name?.toLowerCase()?.includes(searchTextLower) ||
      filter?.driver.momo_wallet_details.number
        ?.toLowerCase()
        ?.includes(searchTextLower) ||
      filter?.payment_mode?.toLowerCase()?.includes(searchTextLower) ||
      formattedDate.includes(searchTextLower)

    return isTextMatch
  }
  // Filtered and paginated data for the current page
  // const [itemsPerPage] = useState(10)

  const handlePageChange = (page: number) => {
    setActivePage(page)
  }

  // console.log(activePage, pageSize)
  // console.log(data)

  // const filteredData = data?.filter(filterData)

  const filteredData = (
    collectionData?.collection_request as ResponseType[]
  )?.filter((item: any) => {
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date)
      normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
      return normalizedDate
    }
    const from =
      (startDate as any) instanceof Date
        ? normalizeDate(startDate)
        : normalizeDate(startDate)
    const to =
      (endDate as any) instanceof Date
        ? normalizeDate(endDate)
        : normalizeDate(endDate)
    const dateCreated = normalizeDate(item?.created_at)
    // Convert date_created to Date object
    // Check if dateCreated falls within the range (inclusive)
    if (startDate && endDate) {
      return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
    } else if (startDate) {
      return dateCreated >= from // Only from date is set
    } else if (endDate) {
      return dateCreated <= to // Only to date is set
    }
    return true // No filter applied
  })
  const startIndex = (activePage - 1) * pageSize
  const endIndex = startIndex + pageSize

  const lengthOfItems = filteredData?.length
  // console.log(lengthOfItems)
  // const paginatedData = filteredData?.slice(startIndex, endIndex)

  const endOffset = activePage + pageSize
  const paginatedData = filteredData?.slice(activePage, endOffset)

  useEffect(() => {
    if (searchText || startDate || endDate ) {
      setActivePage(0)
   
    }
  }, [searchText, startDate, endDate])
  useEffect(() => {
    if ( pageSize) {
      setActivePage(0)
      setSearchText("")
      setStartDate("")
      setEndDate("")
    }
  }, [ pageSize])

  const change = filteredData?.map((item) => {
    return {
      drive_name: item?.driver?.full_name,
      driver_phoneNumber: item.driver?.phone_number_1,
      driver_dob: item?.driver?.dob,
      gender: item?.driver.gender,
      amount: `GHS ${item?.amount}`,
      created_at: `${moment(item?.created_at).format("LLL")}`,
      reference: item?.reference_number,
      driver_id: item?.driver?.id_card_type,
      driver_id_number: item?.driver?.id_number,
      driver_account_status: item?.driver?.account_status,
      company_staff: item?.company_staff?.name,
      company_staff_phoneNumber:
        item?.company_staff?.phone_number_one?.toString(),
    }
  })
  const change1 = collectionData?.collection_request?.map((item: any) => {
    return {
      drive_name: item?.driver?.full_name,
      driver_phoneNumber: item.driver?.phone_number_1,
      driver_dob: item?.driver?.dob,
      gender: item?.driver.gender,
      amount: `GHS ${item?.amount}`,
      created_at: `${moment(item?.created_at).format("LLL")}`,
      reference: item?.reference_number,
      driver_id: item?.driver?.id_card_type,
      driver_code: item?.driver?.driver_code,
      driver_id_number: item?.driver?.id_number,
      driver_account_status: item?.driver?.account_status,
      company_staff: item?.company_staff?.name,
      company_staff_phoneNumber:
        item?.company_staff?.phone_number_one?.toString(),
    }
  })

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  //-------------------
  const handleDownload = () => {
    // Check if there is data to download
    // console.log(paginatedData)
    if (change.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    if (startDate || endDate || searchText) {
      const firstItem = change[0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = change?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"collection-data"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } else {
      const firstItem = change[0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = change1?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"collection-data"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }
  }

  //--------------------

  return (
    <>
      <CustomHeader title="Collections" />
      <DetailDrawer
        title="Collection Detail"
        open={open}
        size={400}
        onClose={() => setOpen(false)}
        children={<CollectionDetail content={selectedRow} />}
      />
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {load ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  "GH₵ " +
                  (
                    totalcollection?.total_collections?.aggregate?.sum
                      ?.amount ?? 0
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total collection
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
      </div>

      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70"
            >
              List of Collections{" "}
              <span className=" text-white">{totalPageSize}</span>
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-y-6 bg-white  px-3 py-3 ">
              <div className="flex shrink-0 flex-col justify-end gap-2 sm:flex-row">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
                <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "reference number",
                      "driver's code",
                      "driver's name",
                      "amount collected",
                      "Mode of Payment",
                      "wallet number",
                      "Date Received",
                      "staff/Agent",
                      "account balance",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4  text-center"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                   
                    const isLast = index === paginatedData?.length - 1

                    const classes = isLast
                      ? "px-2 py-3  text-center"
                      : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        onClick={() => {
                          setSelectedRow(item)
                          setOpen(true)
                        }}
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.reference_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.driver?.driver_code}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.driver?.full_name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(item?.amount ?? 0).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.payment_mode}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.payment_mode === "cash"
                              ? "N/A"
                              : item?.driver?.momo_wallet_details?.number}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.created_at).format("LLL")}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex  flex-col gap-2 text-center">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? `${item?.company_staff?.name}`
                                  : `${item?.sub_agent?.first_name} ${item?.sub_agent?.last_name}`}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? item?.company_staff?.phone_number_one
                                  : item?.sub_agent?.phone_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              User type:{" "}
                              <span className="font-normal">
                                {item?.company_staff
                                  ? "Company Staff"
                                  : "Sub Agent"}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(item?.driver_current_balance ?? 0).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </Typography>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {(loading || loadingData) && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedData && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedData?.length < 1 && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {/* Paginator */}

        {
          // <GeneralPagination
          //   totalItems={
          //     searchText || startDate || endDate
          //       ? lengthOfItems
          //       : totalPageSize!
          //   }
          //   itemsPerPage={pageSize}
          //   currentPage={activePage}
          //   onPageChange={handlePageChange}
          // />

          <PerPageAndPagination
            total={
              (searchText || startDate || endDate
                ? lengthOfItems
                : totalPageSize!) ?? 0
            }
            currentPage={activePage}
            setCurrentPage={setActivePage}
            perPage={pageSize}
            setPerPage={setPageSize}
          />
        }
      </div>
    </>
  )
}

export default Invest2InsureCollection

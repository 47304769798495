import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import moment from "moment"
import { useQuery } from "@apollo/client"
import { GET_DRIVER_CLOSE_ACCOUNT_REQUESTS } from "../../../GraphQl/queries"
import CloseAccountConfirmation from "../../../config/CloseAccountConfirmationBox"
import { useMutation } from "@apollo/client"
import { CLOSE_DRIVER_ACCOUNT } from "../../../GraphQl/mutations"
import { localDriver } from "./manage"
import { toast } from "react-toastify"
import { BiSearch } from "react-icons/bi"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

type DRIVER_CLOSE_ACCOUNT_TYPE = {
  __typename: "drivers"
  driver_id: string
  account_status: "active" | "inactive" // Assuming only these two statuses
  address: string
  agent_id: string | null
  balance: number
  bank_details: { branch: string; bank_name: string; account_number: string }
  created_at: string
  created_by: string
  driver_code: string
  email: string
  first_name: string
  full_name: string
  gender: "male" | "female" // Assuming only these two genders
  id_number: string
  is_deleted: boolean
  other_name: string
  phone_number_1: string
  phone_number_2: string
  surname: string
  station: string
  updated_at: string
  sub_agent: null | {
    __typename: "sub_agents"
    id: string
    first_name: string
    last_name: string
    phone_number: string
  }
  company_staff: {
    __typename: "company_staff"
    id: string
    name: string
    phone_number: string
  }
}

const driver = JSON.parse(localStorage.getItem("driver") || "{}") as localDriver
const useUpdateDriver = () => {
  const [closeDriver, { loading, error, data }] =
    useMutation(CLOSE_DRIVER_ACCOUNT)
  return {
    closeDriver,
    loading,
    error,
    data,
  }
}

const CloseAccountRequest = () => {
  // const [colors, setColors] = useState<string[]>()
  const [searchText, setSearchText] = useState("")
  // const userData = localStorage.getItem("user")
  const [open, setOpen] = useState<boolean>(false)
  const [item, setItem] = useState<DRIVER_CLOSE_ACCOUNT_TYPE | null>(null)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {  user: stateUser } = useSelector(
    (state: RootState) => state.auth
  )

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  //--query----
  const {
    loading,
    refetch: refetchDrivers,
    data: DRIVER_CLOSE_ACCOUNT_DATA,
  } = useQuery<{
    drivers: DRIVER_CLOSE_ACCOUNT_TYPE[]
  }>(GET_DRIVER_CLOSE_ACCOUNT_REQUESTS)
  // console.log(DRIVER_CLOSE_ACCOUNT_DATA)

  const {
    closeDriver,
  
    data,
  } = useUpdateDriver()
  const handleCloseAccountFxn = async () => {
    try {
      const updated_at = new Date().toISOString()
      await closeDriver({
        variables: { driver_id: driver.driver_id, updated_at },
      })

      console.log(
        "Driver updated successfully:",
        data?.update_drivers?.returning[0]?.id
      )
      toast.success("Account clossed successfully")
      refetchDrivers()
    } catch (err) {
      console.error("Error updating driver:", err)
    }
  }

  const filterData = (filter: DRIVER_CLOSE_ACCOUNT_TYPE) => {
    const isTextMatch =
      filter?.account_status
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.agent_id?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.driver_id?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.balance
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.email
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.station
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.full_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.address
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }



  const totalItems = DRIVER_CLOSE_ACCOUNT_DATA?.drivers
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.created_at)
      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])



  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  // console.log(totalItems)

  const handleDownload = () => {
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    if (searchText || startDate || endDate) {
      const type = totalItems?.map((item: DRIVER_CLOSE_ACCOUNT_TYPE) => ({
        "Driver code": item?.driver_code,
        "Driver name": item?.full_name,
        "Phone number": item?.phone_number_1,
        Address: item?.address,
        "Wallet number": item?.phone_number_1,
        "Current balance": item?.balance,
        "Agent/staff information": `${item?.company_staff?.name} - ${item?.company_staff?.phone_number}`,
        Datecreated: moment(item?.created_at).format("LLL"),
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"close-account-request"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    }
    const type = DRIVER_CLOSE_ACCOUNT_DATA?.drivers?.map(
      (item: DRIVER_CLOSE_ACCOUNT_TYPE) => ({
        "Driver code": item?.driver_code,
        "Driver name": item?.full_name,
        "Phone number": item?.phone_number_1,
        Address: item?.address,
        "Wallet number": item?.phone_number_1,
        "Current balance": item?.balance,
        "Agent/staff information": `${item?.company_staff?.name} - ${item?.company_staff?.phone_number}`,
        Datecreated: moment(item?.created_at).format("LLL"),
      })
    )
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"close-account-request"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  // console.log(paginatedData)
  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  return (
    <>
      <CloseAccountConfirmation
        show={open}
        setShow={setOpen}
        onConfirm={() => handleCloseAccountFxn()}
        children={
          <div className=" mt-5">
            <div className=" text-left mt-2">
              <div className=" flex items-center gap-x-1">
                <p className="">Driver Name:</p>
                <p className=" font-[500]">{item?.full_name}</p>
              </div>
              <div className=" flex items-center gap-x-1">
                <p>Driver Code: </p>
                <p className=" font-[500]">{item?.driver_code}</p>
              </div>
            </div>
          </div>
        }
      />

      <div>
        <CustomHeader title="Close Account Requests" />
        <div className="w-full">
          <div className="flex flex-col gap-y-2 bg-white p-3 rounded-lg">
            <div className="flex shrink-0 flex-col justify-end gap-2 flex-wrap lg:flex-row pl-3">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
            <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  value={searchText}
                  onChange={handleSearch}
                  label="Search"
                  icon={<BiSearch className="h-5 w-5" />}
                />
              </div>
              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
            <div className="bg-black pt-5 rounded-t-2xl">
              <Typography
                variant="h6"
                style={{
                  color:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="px-4 pb-4 font-bold leading-none opacity-70 capitalize"
              >
                List of All Close Account Requests
                {DRIVER_CLOSE_ACCOUNT_DATA?.drivers && (
                  <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                    {DRIVER_CLOSE_ACCOUNT_DATA?.drivers?.length}
                  </span>
                )}
              </Typography>
            </div>
            <CardBody className="px-0 py-0">
              <div
                className="overflow-x-auto overflow-y-auto"
                style={{ maxHeight: "65vh" }}
              >
                <table className=" bg-white w-full min-w-max table-auto">
                  <thead className="">
                    <tr>
                      {[
                        "Driver code",
                        "driver name",
                        "phone number",
                        "address",
                        "wallet number",
                        "station name",
                        "current balance",
                        "Agent/staff information",
                        "datecreated",
                        "actions",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item, index) => {
                      const isLast = index === paginatedData?.length - 1
                      const classes = isLast
                        ? "p-2  text-center"
                        : "p-2 border-b-2 border-blue-gray-50 text-center"

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.driver_code}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.full_name}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.phone_number_1}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.address}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              className=" capitalize font-normal text-xs"
                              color="blue-gray"
                            >
                              {item?.phone_number_1}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              className=" capitalize font-normal text-xs"
                              color="blue-gray"
                            >
                              {item?.address}
                            </Typography>
                          </td>
                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              className=" capitalize font-normal text-xs"
                              color="blue-gray"
                            >
                              {item?.balance}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Full name:{" "}
                                <span className="font-normal">
                                  {item?.company_staff?.name}
                                </span>
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                Phone number:{" "}
                                <span className="font-normal">
                                  {item?.company_staff?.phone_number}
                                </span>
                              </Typography>

                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold text-xs capitalize "
                              >
                                User type:{" "}
                                <span className="font-normal">
                                  {item?.company_staff?.__typename}
                                </span>
                              </Typography>
                            </div>
                          </td>

                          <td
                            className={classes}
                            // onClick={() => handleRowClick(item)}
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {moment(item?.created_at).format("LLL")}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Button
                              onClick={() => {
                                setItem(item)
                                setOpen(true)
                              }}
                              variant="filled"
                              color="green"
                              className="font-normal capitalize"
                            >
                              Close Account
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            )}

            {paginatedData?.length! === 0 && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
          </CardBody>

          {/* {paginatedData && paginatedData?.length !== 0 && !loading && (
            <GeneralPagination
              totalItems={totalItems?.length!}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )} */}
          {paginatedData && paginatedData?.length !== 0 && !loading && (
            <PerPageAndPagination
              total={totalItems?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
              setPerPage={setItemsPerPage}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CloseAccountRequest

import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { useQuery } from "@apollo/client"
import {

  GETALLFUNDSREQUESTPENDING,
  GETBALANCEOFUSERS,
} from "../../../GraphQl/queries"
import {  FundsType } from "../accountfunding"
import { ToastContainer, toast } from "react-toastify"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { DASHBOARD_USERTYPE, parameter_type } from "../../../types/role"
import moment from "moment"
import { BiPlus, BiTrash } from "react-icons/bi"
import { CustomModal } from "../../../components"
import { SubmitHandler, useForm } from "react-hook-form"

import Otp from "../../../components/modal/enterOtpModal"
import { DriverModal } from "../../../components/modal/DriverModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type BalanceType = {
  name: string
  balance: string
  cash_limit: string
  record_id: string
  record_type: string
}

type FormType = {
  amount: number
}

const FundingApproval = () => {
  const [submitting, setSubmitting] = useState(false)
  const [searchRequestText, setRequestText] = useState("")
  const [searchBalanceText, setBalanceText] = useState("")
  const [showCashModal, setCashModal] = useState(false)
  const [action, Setaction] = useState("")
  const [selectedItem, setSelectedItem] = useState<FundsType | null>(null)
  const [openOtp, setOpenOtp] = useState(false)
  // const [formData, setFormData] = useState<PinType | null>(null)
  const [selectedRow, setSelectedRow] = useState({
    parameter_id: "",
    parameter_type: "",
  })

  const {
    companyId,

    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)



  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting},
  } = useForm<FormType>()

  const {
    data: balances,
    loading: loadingBalances,
    refetch: refetchBalance,
  } = useQuery<{
    positive_balance_view: BalanceType[]
  }>(GETBALANCEOFUSERS, {
    variables: {
      company_id: companyId,
    },
  })

  const {
    data: funds,
    loading: loadingFunds,
    refetch: refchFunds,
  } = useQuery<{
    view_fund_account_details: FundsType[]
  }>(GETALLFUNDSREQUESTPENDING, {
    variables: {
      company_id: companyId,
    },
  })

  const handleSearch = (e: any) => {
    setRequestText(e.target.value)
  }

  const handleBalanceSearch = (e: any) => {
    setBalanceText(e.target.value)
  }

  const filterData = (filter: FundsType) => {
    const isTextMatch =
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.branch_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.division_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.role_balance
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_staff_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.status
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase())

    return isTextMatch
  }

  const filterBalance = (filter: BalanceType) => {
    const isTextMatch = filter?.name
      ?.toLowerCase()
      ?.includes(searchBalanceText?.toLowerCase())
    return isTextMatch
  }
  //------------------------
  const [currentPageBa, setCurrentPageBa] = useState(0)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchBalanceText) {
      setCurrentPageBa(0)
    }
  }, [searchBalanceText])


  const totalBallanceItems =
    balances?.positive_balance_view?.filter(filterBalance)

  // const paginatedBalanceData = totalBallanceItems?.slice(
  //   startIndexBa,
  //   endIndexBa
  // )

  const endOffsetBa = currentPageBa + itemsPerPage
  const paginatedBalanceData = totalBallanceItems?.slice(
    currentPageBa,
    endOffsetBa
  )

  //------------------
  const [currentPageFa, setCurrentPageFa] = useState(0)

  useEffect(() => {
    if (searchRequestText) {
      setCurrentPageBa(0)
    }
  }, [searchRequestText])


  const totalFundItems = funds?.view_fund_account_details?.filter(filterData)

  // const paginatedFundsData = totalFundItems?.slice(startIndex, endIndex)

  const endOffsetFa = currentPageFa + itemsPerPage
  const paginatedFundsData = totalFundItems?.slice(currentPageFa, endOffsetFa)

  const handleApprove = async (item: FundsType, response: string) => {
    const payload = {
      request_id: item?.funds_request_id,
      company_id: companyId,
      parameter_type: item?.parameter_type,
      parameter_id: item?.parameter_id,
      previous_balance: String( item?.parameter_type === parameter_type.agent_id
        ? item?.agent_balance ?? "0"
        : item?.parameter_type === parameter_type.broker_id
        ? item?.broker_balance ?? "0"
        : item?.parameter_type === parameter_type.staff_id
        ? item?.staff_balance ?? "0"
        : item?.parameter_type === parameter_type?.branch_id
        ? item?.branch_balance ?? "0"
        : item?.parameter_type === parameter_type?.division_id
        ? item?.division_balance ?? "0"
        : item?.role_balance ?? "0"),
      
      amount: item?.amount,
      response: response,
      usertype: DASHBOARD_USERTYPE.company,
    }
    setSubmitting(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/approve-users-account`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      )

      const req = await res.json()
      // console.log(res.ok)

      if (res.ok) {
        toast.success(req?.message)
        refchFunds()
        refetchBalance()
      }
      if (req.error === 403) {
        toast.error(req?.data ?? req?.message)
      }
      // console.log(res)

      setSubmitting(false)
    } catch (error: any) {
      setSubmitting(false)

      toast.error(error?.message ?? "An error occured")
    }
  }

  const removeBalance = async (record_id: string, record_type: string) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/remove-users-balance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            record_id,
            record_type,
            usertype: DASHBOARD_USERTYPE.company,
            company_id: companyId,
          }),
        }
      )

      const req = await res.json()

      if (res.ok) {
        toast.success(req?.message)
        refchFunds()
        refetchBalance()
      } else {
        toast.error(req?.detail ?? req?.message)
      }
    } catch (error: any) {
      toast.error(error?.message ?? "An error occured")
    }
  }

  const onUpdateClick = (parameter_type: string, parameter_id: string) => {
    setSelectedRow({
      parameter_id,
      parameter_type,
    })
    Setaction("update")
    setOpenOtp(true)
  }

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      let payload = {
        user_id: selectedRow?.parameter_id,
        parameter_type: selectedRow?.parameter_type,
        amount: data?.amount,
        usertype: DASHBOARD_USERTYPE.company,
        company_id: companyId,
      }

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/update-cash-limit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      )

      const req = await res.json()

      if (res.ok) {
        toast.success(req?.message ?? "Cash limit updated successfully")
        reset()
        refchFunds()
        refetchBalance()
        setCashModal(false)
      } else {
        toast.error(req?.detail ?? req?.message)
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to update cash limit")
    }
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  const handleDownload = () => {
    // Check if there is data to download
    if (!paginatedBalanceData || paginatedBalanceData.length === 0) {
      alert("No data to download.")
      return
    }

    // Define the headers for the CSV file
    const headers = [
      "record_type",
      "name",
      "cash_limit",
      "balance",
      "amount_used",
    ]

    // Transform the data to match the CSV structure
    const transformedData = balances?.positive_balance_view?.map(
      (item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] =
            item[header] !== undefined ? item[header] : "" // Ensure each header has a value
        })
        return {
          ...transformedItem,
          amount_used: `${(
            parseInt(item?.cash_limit || "0") - parseInt(item?.balance || "0")
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        }
      }
    )

    // Convert the data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData || [],
    })

    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)

    // Create a download link and trigger download
    const a = document.createElement("a")
    a.href = url
    a.download = `staff-balance-report.csv`
    document.body.appendChild(a)
    a.click()

    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <>
      <CustomHeader title="Funding Request" />
      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"xs"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() => {
              action === "approve"
                ? handleApprove(selectedItem!, "approve")
                : action === "disapprove"
                ? handleApprove(selectedItem!, "disapprove")
                : action === "update"
                ? setCashModal(true)
                : setOpenOtp(false)
            }}
            onClose={() => setOpenOtp(false)}
          />
        }
        // () => handleCreateSinglePaymentOrder(chosenId)
      />

      <CustomModal
        title="Update cash limit"
        onClose={() => setCashModal(false)}
        open={showCashModal}
        children={
          <>
            <ToastContainer />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" rounded-lg overflow-hidden w-full"
            >
              <div className="">
                <div className="flex flex-col items-end justify-end">
                  <div className="w-full">
                    <div className="mb-6 flex flex-col gap-2">
                      <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Cash limit amount
                        </Typography>
                        <Input
                          value={undefined}
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          crossOrigin="anonymous"
                          label="Amount"
                          {...register("amount")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end mt-5">
                <Button
                  type="submit"
                  size="sm"
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="flex flex-row items-center font-normal"
                >
                  <BiPlus size={20} />

                  {isSubmitting ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Update cash limit"
                  )}
                </Button>
              </div>
            </form>
          </>
        }
      />

      <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
        <div className="bg-black p-3 text-white">
          <Typography
            style={{
              color:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-lg"
          >
            List of All Accounts Funding Request
          </Typography>
        </div>
        <div className="p-3 bg-white rounded-b-xl">
          <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
            <div className="p-3">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchRequestText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-center">
              <thead className="">
                <tr>
                  {[
                    "no",
                    "Account",
                    "Previous Balance",
                    "Funding Amount",
                    "Account Limit",
                    "Date Requested",
                    "",
                  ].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedFundsData?.map((item, index: number) => {
                  const isLast = index === paginatedFundsData?.length - 1
                  const classes = isLast
                    ? "px-4 py-2  text-center"
                    : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                  return (
                    <tr
                      key={index.toString()}
                      className="hover:bg-gray-100 transition-all even:bg-gray-200"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.parameter_type === parameter_type.agent_id
                            ? item?.agent
                            : item?.parameter_type === parameter_type?.broker_id
                            ? item?.broker_name
                            : item?.parameter_type === parameter_type.staff_id
                            ? item?.company_staff_name
                            : item?.parameter_type === parameter_type?.branch_id
                            ? item?.branch_name
                            : item?.parameter_type ===
                              parameter_type?.division_id
                            ? item?.division_name
                            : item?.company_role_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHS{" "}
                          {item?.parameter_type === parameter_type.agent_id
                            ? (
                                parseInt(item?.agent_balance) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : item?.parameter_type === parameter_type.broker_id
                            ? (
                                parseInt(item?.broker_balance) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : (
                                parseInt(item?.staff_balance) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHS{" "}
                          {parseInt(item?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHS{" "}
                          {item?.parameter_type === parameter_type.agent_id
                            ? (
                                parseInt(item?.agent_cash_limit) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : item?.parameter_type === parameter_type.broker_id
                            ? (
                                parseInt(item?.broker_cash_limit) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : (
                                parseInt(item?.staff_cash_limit) || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {moment(item?.created_at).format("LLL")}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <div className="w-full flex flex-row items-center gap-x-2">
                          <Button
                            onClick={() => {
                              Setaction("approve")
                              setSelectedItem(item)
                              setOpenOtp(true)
                            }}
                            size="sm"
                            color="green"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            {action === "approve" && submitting
                              ? "loading..."
                              : "Approve"}
                          </Button>
                          <Button
                            onClick={() => {
                              Setaction("disapprove")
                              setSelectedItem(item)
                              setOpenOtp(true)
                            }}
                            size="sm"
                            color="red"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            {action === "disapprove" && submitting
                              ? "loading..."
                              : "disapprove"}
                          </Button>
                          <Button
                            onClick={() =>
                              onUpdateClick(
                                item?.parameter_type,
                                item?.parameter_id
                              )
                            }
                            size="sm"
                            color="green"
                            className="w-full flex items-center justify-center text-[14px] font-normal capitalize"
                          >
                            Update cash limit
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>

          {loadingFunds && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedFundsData && !loadingFunds && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedFundsData?.length! === 0 && !loadingFunds && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedFundsData &&
            paginatedFundsData?.length !== 0 &&
            !loadingFunds && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalFundItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPageBa}
              //   onPageChange={handlePageChangeFa}
              // />

              <WhitePaginateComponent
                totalPages={totalFundItems?.length!}
                currentPage={currentPageFa}
                setCurrentPage={setCurrentPageFa}
                perPage={itemsPerPage}
              />
            )}
        </div>
      </div>

      <div className="w-full rounded-lg lg:col-span-2 overflow-hidden mt-10">
        <div className="bg-black p-3 text-white">
          <div className="flex w-full justify-between">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              Account balances
            </Typography>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="p-3 bg-white rounded-b-xl">
          <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
            <div className="p-3">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchBalanceText}
                  onChange={handleBalanceSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-center">
              <thead className="">
                <tr>
                  {[
                    "no",
                    "Account",
                    "Cash limit",
                    "Balance",
                    "Amount Used",
                    "Account Type",
                    "",
                  ].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBalanceData?.map((item, index: number) => {
                  const isLast = index === paginatedBalanceData?.length - 1
                  const classes = isLast
                    ? "px-4 py-2  text-center"
                    : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                  return (
                    <tr
                      key={index.toString()}
                      className="hover:bg-gray-100 transition-all even:bg-gray-200"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.name}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.cash_limit).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.balance).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {(
                            parseInt(item?.cash_limit || "0") -
                            parseInt(item?.balance || "0")
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.record_type === "sub_agents"
                            ? "Agent"
                            : item?.record_type === "company_staff"
                            ? "Staff"
                            : item?.record_type === "broker"
                            ? "Broker"
                            : ""}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content="Delete">
                          <IconButton
                            onClick={() =>
                              removeBalance(item?.record_id, item?.record_type)
                            }
                            size="sm"
                            color="red"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            <BiTrash />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>

          {loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedBalanceData && !loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedBalanceData?.length! === 0 && !loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedBalanceData &&
            paginatedBalanceData?.length !== 0 &&
            !loadingBalances && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalBallanceItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPageBa}
              //   onPageChange={handlePageChangeBa}
              // />

              <WhitePaginateComponent
                totalPages={totalBallanceItems?.length!}
                currentPage={currentPageBa}
                setCurrentPage={setCurrentPageBa}
                perPage={itemsPerPage}
              />
            )}

   
        </div>
      </div>

      <div className="h-20" />
    </>
  )
}

export default FundingApproval

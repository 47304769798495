import {
  Button,
  Typography,
  Tooltip,
  Input,
  Spinner,
} from "@material-tailwind/react"
import React from "react"
import {
  AMOUNT_PAYABLE_EVERYDAY,
  REPLENISHMENT_RECEIPTS_PAY_CASH,
  REPLENISHMENT_RECEIPTS_PAY_CHEQUE,
} from "../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import {} from "recharts"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"

import { useState, useRef } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

const Otp = ({
  onConfirm,
  onClose,
}: {
  onConfirm: () => void
  onClose: () => void
}) => {
  const {


    token,
  } = useSelector((state: RootState) => state.auth)
  const [otp, setOtp] = useState<string[]>(Array(4).fill(""))
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<(HTMLInputElement | null)[]>([])
  const handleOtpChange = (index: number, value: string) => {
    if (value === "") {
      const newOtp = [...otp]
      newOtp[index] = ""
      setOtp(newOtp)
      if (index > 0) {
        inputRef.current[index - 1]?.focus()
      }
      return
    }
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    if (value !== " " && index < 4 - 1) {
      inputRef.current[index + 1]?.focus()
    }
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const values = otp.join("")
    if (!values) {
      toast.error("Invalid Pin")
      return
    }
    setLoading(true)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/approve-user-pin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            pin: values,
          }),
        }
      )

      const req = await response.json()
      if (req?.approval_status === 1) {
        onConfirm()
        onClose()
        // toast.success(req?.message ?? "Pin successfully set")
      } else {
        toast.error("Invalid Pin. Permission Denied")
      }
    } catch (error) {
      toast.error("Some error occured ")
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className=" -mt-5 w-full">
      <p>Please enter pin to proceed</p>
      <div className=" flex flex-col gap-y-4  py-7 pb-8 items-center ">
        <form
          onSubmit={handleSubmit}
          className=" w-full flex flex-col items-center"
        >
          <div className=" flex  gap-x-8 text-[#040A1D]   items-center font-bold text-[20px] w-full">
            {otp.map((val, index) => (
              <input
                key={index}
                type="password"
                maxLength={1}
                // value={val}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                ref={(el) => {
                  if (el) {
                    inputRef.current[index] = el
                  }
                }}
                className="  w-14 h-14 rounded-md bg-[#F4F5F7] text-center text-[#383838] font-bold text-[24px] focus:outline-[#DF327B] "
              />
            ))}
          </div>
          <div className=" flex  gap-x-7  w-full mt-5 self-start justify-start">
            <button
              onClick={onClose}
              type="button"
              className=" font-medium bg-red-500 px-4 rounded-md text-[18px] text-white py-[0.4rem] mt-9"
            >
              Cancel
            </button>
            <button
              type="submit"
              className=" font-medium text-[18px] px-4 rounded-md bg-green-500 text-white py-[0.4rem] mt-9"
            >
              <p className=" flex gap-x-[0.4rem] items-center justify-center text-center w-full">
                {loading ? <Spinner /> : null}
                Proceed
              </p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Otp

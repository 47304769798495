import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { BASEURL } from "../util"
// Define types
type checkpaymentType = {
  amount: number
  cheque_number: string
  is_cleared: boolean
  date_on_cheque: string
  date_replenished: string
  created_at: string
  policy_number: string
  policy_id: string
  policy_status: string
  payment_type: string
  replenished_by_name: string
  id: string
}

type AmountType = {
  id: string
  amount: number
  name?: string | null
}

type cashpaymentType = {
  name: string
  ID: string
  amount: number
  is_replenished: boolean
  replenished_by_name: string
  date_on_cheque: string
  date_replenished: string
  created_at: string
  policy_number: string
  policy_status: string
  policy_id: string
  created_by: string
  payment_type: string
  id: string
}

type selectedUserType = {
  username: string
  usercode: string
}

type drivercollectionType = {
  reference_number: string
  replenished_by_name: string
  status: string
  date_replenished: string
  amount: number
  created_at: string
  policy_id: string
  driver: {
    driver_code: string
    full_name: string
    phone_number_1: string
  }
  is_replenished: boolean
  payment_mode: string
  id: string
  created_by: string
  agent_id: string
}

type PaymentType = {
  id: string
  policy_id: string
}

// Array types
type CashpaymentArrayType = cashpaymentType[]
type CheckpaymentArrayType = checkpaymentType[]
type DriverCollectionArrayType = drivercollectionType[]
type paymentArrayType = PaymentType[]

export interface ReplenishmentState {
  selectedId: string
  cashLimit: number
  currentReferenceNumber: string
  selectedRows: paymentArrayType
  selectedRowAmount: AmountType[]
  selectedUser: selectedUserType
  balance: string
  staffDetails: {
    name: string
    staffName: string
  }
  intermediaryDetails: {
    intermediaryId: string
    staffagentName: string
    cachedId: string
  }
  chequePayments: CheckpaymentArrayType
  cashPayments: CashpaymentArrayType
  driverCollections: DriverCollectionArrayType
  loading: boolean
  downloadPay: boolean
  usertype: string
  total_amount: number
  selectedIntermediaryId: string
}

const initialState: ReplenishmentState = {
  selectedId: "",
  total_amount: 0,
  currentReferenceNumber: "",
  selectedRowAmount: [],
  selectedRows: [],
  selectedIntermediaryId: "",
  cashLimit: 0,
  usertype: "",
  selectedUser: {
    username: "",
    usercode: "",
  },
  intermediaryDetails: {
    intermediaryId: "",
    staffagentName: "",
    cachedId: "",
  },
  balance: "",
  staffDetails: {
    name: "",
    staffName: "",
  },
  chequePayments: [],
  cashPayments: [],
  driverCollections: [],
  loading: false,
  downloadPay: false,
}

export const fetchData = createAsyncThunk(
  "replenishment/fetchData",
  async (
    {
      companyId,
      userId,
      usertype,
    }: { companyId: string; userId: string; usertype: string },
    thunkAPI
  ) => {
    try {
      const res = await fetch(`${BASEURL}/get-cash-cheque-payments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_id: companyId,
          user_id: userId,
          client_type: usertype,
        }),
      })
      const data = await res.json()

      console.log(data)

      if (res.ok) {
        if (data.error) {
          toast.error(data.error)
          return thunkAPI.rejectWithValue(data.error)
        } else {
          return data
        }
      } else {
       
        toast.error(data.message || "Failed to fetch data")
        return thunkAPI.rejectWithValue(data.message)
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Something went wrong. Try again!")
    }
  }
)
// Async thunk to clear payments
export const clearPayment = createAsyncThunk(
  "replenishment/clearPayment",
  async (
    {
      channel,
      admin_type,
      selectedRows,
      totalAmount,
      currentReferenceNumber,
      token,
      companyId,
      userId,
      usertype,
    }: any,
    thunkAPI
  ) => {
    try {
      const payload = JSON.stringify(
        selectedRows.map((row: any) => ({
          payment_id: row.id,
          policy_id: row.policy_id ?? "",
        }))
      )
      const res = await fetch(
        `${BASEURL}/clear-payments?payment_mode=${channel}&admintype=${admin_type}&total_amount=${totalAmount}&reference_number=${currentReferenceNumber}&user_id=${userId}&usertype=${usertype}&company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: payload,
        }
      )

      if (res.ok) {
        toast.success("Payments cleared successfully.")
        return true
      } else {
        const errorResponse = await res.json()
        toast.error(errorResponse.message ?? "Failed to clear payments.")
        return thunkAPI.rejectWithValue(errorResponse.message)
      }
    } catch (error: any) {
      toast.error(error.message ?? "Something went wrong. Try again!")
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

const replenishmentSlice = createSlice({
  name: "replenishment",
  initialState,
  reducers: {
    setSelectedIntermediaryId(state, action: PayloadAction<string>) {
      state.selectedId = action.payload
      state.selectedIntermediaryId = action.payload
    },
    clearState: (state) => {
      return initialState // Return a completely fresh initial state
    },
    setBalance(state, action: PayloadAction<string>) {
      state.balance = action.payload
    },
    setStaffDetails(
      state,
      action: PayloadAction<{ name: string; staffName: string }>
    ) {
      state.staffDetails = action.payload
    },
    setIntermediary: (
      state,
      action: PayloadAction<{
        intermediaryId: string
        staffagentName: string
        cachedId: string
      }>
    ) => {
      state.intermediaryDetails = action.payload
    },
    clearIntermediary: (state) => {
      state.intermediaryDetails = {
        intermediaryId: "",
        staffagentName: "",
        cachedId: "",
      }
    },
    setSelectedUser(
      state,
      action: PayloadAction<{ username: string; usercode: string }>
    ) {
      state.selectedUser = action.payload
    },
    setCurrentReferenceNumber(state, action: PayloadAction<string>) {
      state.currentReferenceNumber = action.payload
    },

    setSelectedRow(state, action: PayloadAction<PaymentType[]>) {
      state.selectedRows = action.payload
    },
    setDriverPaymenttoNull(state) {
      state.driverCollections = []
    },

    setUserType(state, action: PayloadAction<string>) {
      state.usertype = action.payload
    },
    resetState: (state) => {
      state.balance = ""
      state.cashPayments = []
      state.chequePayments = []
      state.driverCollections = []
      state.cashLimit = 0
      state.selectedRowAmount = []
      state.total_amount = 0
      state.selectedRows = []
      state.selectedUser = {
        username: "",
        usercode: "",
      }
      state.currentReferenceNumber = ""
    },
    updateTotalAmount: (state, action: PayloadAction<number>) => {
      state.total_amount = action.payload
    },
    setSelectedRowAmount: (state, action: PayloadAction<AmountType[]>) => {
      state.selectedRowAmount = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        const data = action.payload?.data

        state.cashLimit = data.cash_limit
        state.balance = data.current_balance
        state.chequePayments = data.cheque_payments?.map((item: any) => ({
          amount: item.amount,
          cheque_number: item.cheque_number,
          is_cleared: item.is_cleared,
          date_on_cheque: item.date_on_cheque,
          date_replenished: item.date_replenished,
          created_at: item.created_at,
          policy_number: item.policy.policy_number,
          policy_status: item.policy.status,
          replenished_by_name: item.replenished_by_name,
          id: item.id,
          policy_id: item.policy_id,
          payment_type: "cheque",
        }))
        state.cashPayments = data.cash_payments?.map((item: any) => ({
          amount: item.amount,
          name: "",
          ID: item.ID,
          is_replenished: item.is_replenished,
          date_on_cheque: item.date_created,
          date_replenished: item.date_replenished,
          created_at: item.created_at,
          policy_number: item.policy.policy_number,
          policy_status: item.policy.status,
          replenished_by_name: item?.replenished_by_name,
          created_by: item.created_by,
          payment_type: "cash",
          policy_id: item.policy_id,
          id: item.id,
        }))
        state.driverCollections = data.driver_collections?.map((item: any) => ({
          reference_number: item.reference_number,
          amount: item.amount,
          status: item.status,
          date_replenished: item.date_replenished,
          replenished_by_name: item?.replenished_by_name,
          created_at: item.created_at,
          driver: {
            driver_code: item.driver.driver_code,
            full_name: item.driver.full_name,
            phone_number_1: item.driver.phone_number_1,
          },
          is_replenished: item.is_replenished,
          payment_mode: item.payment_mode,
          id: item.id,
        }))
        state.loading = false
      })
      .addCase(fetchData.rejected, (state) => {
        state.loading = false
      })
      .addCase(clearPayment.pending, (state) => {
        state.loading = true
      })
      .addCase(clearPayment.fulfilled, (state) => {
        state.loading = false
        state.downloadPay = true
      })
      .addCase(clearPayment.rejected, (state) => {
        state.loading = false
      })
  },
})

export const {
  setSelectedIntermediaryId,
  setStaffDetails,
  setIntermediary,
  clearIntermediary,
  setBalance,
  setSelectedUser,
  setUserType,
  resetState,
  updateTotalAmount,
  setSelectedRowAmount,
  setSelectedRow,
  setCurrentReferenceNumber,
  clearState,
  setDriverPaymenttoNull,
} = replenishmentSlice.actions
export default replenishmentSlice.reducer

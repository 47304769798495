// import {
//   Button,
//   CardBody,
//   DialogFooter,
//   IconButton,
//   Input,
//   Spinner,
//   Tab,
//   TabPanel,
//   Tabs,
//   TabsBody,
//   TabsHeader,
//   Tooltip,
//   Typography,
// } from "@material-tailwind/react"
// import React, { useEffect, useState, useMemo } from "react"
// import {
//   CustomDeleteModal,
//   CustomModal,
//   DetailDrawer,
//   HeaderIcon,
//   RenderAdminDetail,
// } from "../../../../components"
// import useSWR from "swr"
// import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../../util"
// import {
//   AiOutlineArrowLeft,
//   AiOutlineArrowRight,
//   AiOutlineDelete,
//   AiOutlineEdit,
// } from "react-icons/ai"
// import { AdminType, CreateAdminType, UpdateType } from "../../../../types/admin"
// import { ToastContainer, toast } from "react-toastify"
// import { UserType } from "../../../../types/user"
// import { SubmitHandler, useForm } from "react-hook-form"
// import { AddAdminModal } from "../../../../components/forms/add-admin"
// import { CustomHeader } from "../../../../components/headers/CustomHeader"
// import { useHistory } from "react-router-dom"
// import { useQuery } from "@apollo/client"
// import CreateAdmin, { RoleType } from "./create-staffs"
// import { GET_ROLES } from "../../../../GraphQl/queries"
// import { BiBlock } from "react-icons/bi"

import React from 'react';

const InsuranceCompanyView = () => {
  return (
    <div>
      Helo
    </div>
  );
}

export default InsuranceCompanyView;


// const InsuranceCompanyView = () => {
//   const history = useHistory()
//   const [user, setUser] = useState<UserType>()
//   const [searchText, setSearchText] = useState("")
//   const [activePage, setActivePage] = useState(1)
//   const [token, setToken] = useState()
//   const [loading, setLoading] = useState(false)
//   const [showDeleteModal, SetShowDeleteModal] = useState(false)
//   const [open, setOpen] = useState(false)
//   const [openDrawer, setOpenDrawer] = React.useState(false)
//   const [selectedRow, setSelectedRow] = useState<UpdateType>()
//   const [selectedOption, setOption] = useState("Intermediaries")
//   const [isUpdating, setIsUpdating] = useState(false)
//   const [showUpdateModal, setUpdateModal] = useState(false)

//   const { data: roleData, refetch } = useQuery<{ roles: RoleType[] }>(GET_ROLES)

//   const defaultValues = useMemo(() => {
//     return {
//       ...selectedRow,
//     }
//   }, [showUpdateModal])

//   const {
//     register: updateRegister,
//     handleSubmit: updateSubmit,
//     setValue,
//     formState: { errors: updateError, isSubmitting: isUpdateSubmitting },
//   } = useForm<UpdateType>({
//     defaultValues: defaultValues,
//   })

//   const PAGE_SIZE = 6

//   const handleOpen = () => {
//     history.push(`/dashboard/create-company`)
//     //()
//   }

//   const handleRowClick = (item: UpdateType) => {
//     setSelectedRow(item)
//     setOpenDrawer(true)
//   }

//   const handleSearch = (e: any) => {
//     setSearchText(e.target.value)
//     setActivePage(1)
//   }

//   const { data, isLoading, error, mutate } = useSWR(
//     `${process.env.REACT_APP_BASE_URL}/admin/auth/admins`,
//     () =>
//       fetch(`${process.env.REACT_APP_BASE_URL}/admin/auth/admins`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }).then((res) => res.json()),
//     { refreshInterval: 1000 }
//   )

//   const filterData = (admin: AdminType) => {
//     const isTextMatch =
//       admin?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
//       admin?.role?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
//       admin?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
//       admin?.phoneNumber?.toLowerCase()?.includes(searchText?.toLowerCase())
//     return isTextMatch
//   }

//   // Filtered and paginated data for the current page
//   const paginatedData = data?.admins
//     ?.filter(filterData)
//     .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)

//   const nextPage = () => {
//     if (
//       (activePage - 1) * PAGE_SIZE <
//       (data?.admins?.filter(filterData).length || 0)
//     ) {
//       setActivePage(activePage + 1)
//     }
//   }

//   const prevPage = () => {
//     if (activePage > 1) {
//       setActivePage(activePage - 1)
//     }
//   }

//   useEffect(() => {
//     token && mutate()
//   }, [token])

//   useEffect(() => {
//     let storage = localStorage.getItem("user")
//     if (storage) {
//       setUser(JSON.parse(storage))
//     }
//   }, [])

//   // set default values
//   React.useEffect(() => {
//     if (defaultValues) {
//       Object.entries(defaultValues).forEach(([key, value]) => {
//         setValue(key as keyof typeof defaultValues, value)
//       })
//     }
//   }, [defaultValues])

//   const handleDelete = async () => {
//     setOpenDrawer(false)
//     try {
//       setLoading(true)
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/auth/delete-admin`,
//         {
//           method: "DELETE",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             admin_id: selectedRow?.admin_id,
//           }),
//         }
//       )

//       const req = await response.json()

//       if (response.ok) {
//         toast.success("Admin deleted successfully")
//         mutate()
//         SetShowDeleteModal(false)
//         if (user?.email === selectedRow?.email) {
//           localStorage.clear()
//           history?.push("/")
//           //()
//         }
//       } else {
//         toast.error(req?.msg)
//       }
//       setLoading(false)
//     } catch (error) {
//       toast.error("Error deleting admin")
//       setLoading(false)
//     }
//   }

//   const handleUpdate: SubmitHandler<UpdateType> = async (data) => {
//     // try {
//     //   setLoading(true);
//     //   const response = await fetch(`${BASEURL}/auth/delete-admin`, {
//     //     method: "POST",
//     //     headers: {
//     //       Authorization: `Bearer ${token}`,
//     //       "Content-Type": "application/json",
//     //     },
//     //     body: JSON.stringify({
//     //       role: selectedRow?.role,
//     //       department: selectedRow?.department,
//     //       email: selectedRow?.email,
//     //     }),
//     //   });
//     //   const req = await response.json();
//     //   if (response.ok) {
//     //     toast.success(req?.msg);
//     //     mutate();
//     //     setUpdateModal(false);
//     //   } else {
//     //     toast.info(req?.msg);
//     //   }
//     //   setLoading(false);
//     // } catch (error: any) {
//     //   console.log(error?.message);
//     //   toast.error("Error updating admin");
//     //   setLoading(false);
//     // }
//   }

//   const userData = localStorage.getItem("user")
//   React.useEffect(() => {
//     if (userData) {
//       setUser(JSON.parse(userData))
//     }
//   }, [])

//   const [colors, setColors] = useState<string[]>()

//   useEffect(() => {
//     if (userData) {
//       const data: UserType = JSON.parse(userData)

//       setColors(data.colors)
//     }
//   }, [])

//   return (
//     <>
//       <CustomDeleteModal
//         open={showDeleteModal}
//         onClose={() => SetShowDeleteModal(false)}
//         onSubmit={handleDelete}
//         loading={loading}
//         title="Delete selected admin"
//       />

//       <DetailDrawer
//         size={350}
//         title="Admin detail"
//         open={openDrawer}
//         children={<>{/* <RenderAdminDetail content={selectedRow} /> */}</>}
//         onClose={() => setOpenDrawer(false)}
//       />

//       {/* update modal */}
//       <CustomModal
//         onClose={() => setUpdateModal(false)}
//         open={showUpdateModal}
//         size={"lg"}
//         title="Update Admin"
//         children={
//           <>
//             <ToastContainer />
//             <div>
//               <form onSubmit={updateSubmit(handleUpdate)}>
//                 <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6 capitalize">
//                   <div className="w-full">
//                     <Typography
//                       variant="h6"
//                       color="blue-gray"
//                       className="mb-2 font-normal text-base"
//                     >
//                       First name
//                     </Typography>
//                     <Input
//                       crossOrigin="anonymous"
//                       error={!!updateError.first_name}
//                       size="lg"
//                       disabled
//                       className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
//                       labelProps={{
//                         className: "before:content-none after:content-none",
//                       }}
//                       {...updateRegister("first_name", { required: true })}
//                     />
//                   </div>
//                   <div className="w-full">
//                     <Typography
//                       variant="h6"
//                       color="blue-gray"
//                       className="mb-2 font-normal text-base"
//                     >
//                       Last name
//                     </Typography>
//                     <Input
//                       crossOrigin="anonymous"
//                       error={!!updateError.last_name}
//                       size="lg"
//                       className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
//                       labelProps={{
//                         className: "before:content-none after:content-none",
//                       }}
//                       {...updateRegister("last_name")}
//                     />
//                   </div>
//                 </div>
//                 <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
//                   <div className="w-full">
//                     <Typography
//                       variant="h6"
//                       color="blue-gray"
//                       className="mb-2 font-normal text-base"
//                     >
//                       Phone number
//                     </Typography>
//                     <Input
//                       crossOrigin="anonymous"
//                       error={!!updateError.phone_number}
//                       size="lg"
//                       className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
//                       labelProps={{
//                         className: "before:content-none after:content-none",
//                       }}
//                       {...updateRegister("phone_number")}
//                     />
//                   </div>
//                   <div className="w-full">
//                     <Typography
//                       variant="h6"
//                       color="blue-gray"
//                       className="mb-2 font-normal text-base"
//                     >
//                       Role
//                     </Typography>
//                     <select
//                       id="roles"
//                       className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                       {...updateRegister("role_id", { required: true })}
//                     >
//                       {data?.roles?.map((item: RoleType, index: number) => (
//                         <option key={index} label={item?.name} value={item?.id}>
//                           {item?.name}
//                         </option>
//                       ))}
//                     </select>
//                     {updateError?.role_id && (
//                       <p className="tex-xs mt-1 text-brand-primary">
//                         admin role is required
//                       </p>
//                     )}
//                   </div>
//                 </div>

//                 <DialogFooter>
//                   <Button
//                     variant="text"
//                     color="red"
//                     onClick={() => setUpdateModal(false)}
//                     className="mr-1"
//                   >
//                     <span>Cancel</span>
//                   </Button>
//                   <Button
//                     type="submit"
//                     className="flex items-center justify-center"
//                     variant="gradient"
//                     color="green"
//                   >
//                     {isUpdateSubmitting ? (
//                       <Spinner className="h-4 w-4 text-white" />
//                     ) : (
//                       <span>Update</span>
//                     )}
//                   </Button>
//                 </DialogFooter>
//               </form>
//             </div>
//           </>
//         }
//       />

//       <CustomHeader title="Insurance companies" />
//       <div className="w-full">
//         <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
//           <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
//             <div className="w-full mr-3">
//               <Input
//                 crossOrigin="anonymous"
//                 label="Search"
//                 value={searchText}
//                 onChange={handleSearch}
//                 icon={<i className="fas fa-search text-base"></i>}
//               />
//             </div>
//           </div>

//           <div className="mx-3" />

//           <Button
//             onClick={handleOpen}
//             className="w-full md:w-36 lg:w-36"
//             style={{
//               background:
//                 colors?.length! > 1
//                   ? `${colors![0]?.toLowerCase()}`
//                   : `${DEFAULTPRIMARYCOLOR}`,
//             }}
//           >
//             Add company
//           </Button>
//         </div>
//       </div>

//       <div className="mt-10">
//         <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
//           <div className="bg-black pt-5">
//             <Typography
//               variant="h6"
//               style={{
//                 color:
//                   colors?.length! > 1
//                     ? `${colors![0]?.toLowerCase()}`
//                     : `${DEFAULTPRIMARYCOLOR}`,
//               }}
//               className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
//             >
//               Total companies
//               {/* {data?.admins && (
//                       <span className="text-white text-sm bg-admin-red p-1 rounded-md">
//                         {data?.admins?.length}
//                       </span>
//                     )} */}
//             </Typography>
//           </div>

//           <table className="w-full bg-white min-w-max table-auto">
//             <thead className="">
//               <tr>
//                 {["role", "name", "email", "phone Number", ""].map((head) => (
//                   <th
//                     key={head}
//                     className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
//                   >
//                     <Typography
//                       color="black"
//                       className="font-normal text-sm text-black leading-none opacity-70 capitalize"
//                     >
//                       {head}
//                     </Typography>
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {[]?.map((item: UpdateType, index: number) => {
//                 const isLast = index === paginatedData?.length - 1
//                 const classes = isLast
//                   ? "p-2  text-center"
//                   : "p-2 border-b-2 border-blue-gray-50 text-center"

//                 return (
//                   <tr
//                     key={item?.phone_number + index.toString()}
//                     className="hover:bg-gray-100 transition-all hover:cursor-pointer"
//                   >
//                     <td
//                       className={classes}
//                       onClick={() => handleRowClick(item)}
//                     >
//                       <Typography
//                         variant="small"
//                         color="blue-gray"
//                         className="font-normal text-xs capitalize "
//                       >
//                         {item?.role}
//                       </Typography>
//                     </td>
//                     <td
//                       className={classes}
//                       onClick={() => handleRowClick(item)}
//                     >
//                       <Typography
//                         variant="small"
//                         color="blue-gray"
//                         className="font-normal text-xs capitalize"
//                       >
//                         {`${item?.first_name} ${item?.last_name}`}
//                       </Typography>
//                     </td>
//                     <td
//                       className={classes}
//                       onClick={() => handleRowClick(item)}
//                     >
//                       <Typography
//                         variant="small"
//                         color="blue-gray"
//                         className="font-normal text-xs"
//                       >
//                         {item?.email}
//                       </Typography>
//                     </td>
//                     <td
//                       className={classes}
//                       onClick={() => handleRowClick(item)}
//                     >
//                       <Typography
//                         variant="small"
//                         color="blue-gray"
//                         className="font-normal text-xs capitalize"
//                       >
//                         {item?.phone_number}
//                       </Typography>
//                     </td>

//                     <td className={classes}>
//                       <Tooltip content="Edit Admin">
//                         <IconButton
//                           onClick={() => {
//                             setSelectedRow(item)
//                             setUpdateModal(true)
//                           }}
//                           variant="text"
//                         >
//                           <AiOutlineEdit size={18} color="#222230" />
//                         </IconButton>
//                       </Tooltip>
//                       {/* {user?.role?.name === "super_admin" && ( */}
//                       <Tooltip content="Block Admin">
//                         <IconButton variant="text">
//                           <BiBlock size={18} color="red" />
//                         </IconButton>
//                       </Tooltip>
//                       {/* )} */}
//                       {/* {user?.role?.name === "super_admin" && ( */}
//                       <Tooltip content="Delete Admin">
//                         <IconButton
//                           onClick={() => {
//                             setSelectedRow(item)
//                             SetShowDeleteModal(true)
//                           }}
//                           variant="text"
//                         >
//                           <AiOutlineDelete size={18} color="red" />
//                         </IconButton>
//                       </Tooltip>
//                       {/* )} */}
//                     </td>
//                   </tr>
//                 )
//               })}
//             </tbody>
//           </table>
//         </CardBody>
//         {isLoading && (
//           <div className="mt-10 flex items-center justify-center">
//             <Spinner className="w-1o h-10" />
//           </div>
//         )}

//         {!paginatedData && !isLoading && (
//           <div className="mt-10 flex items-center justify-center">
//             <Typography color="white" variant="h6">
//               No data found
//             </Typography>
//           </div>
//         )}

//         {/* Paginator */}
//         {paginatedData && (
//           <div className="my-10 flex items-center justify-center">
//             <div className="flex items-center gap-8">
//               <IconButton
//                 size="sm"
//                 variant="outlined"
//                 onClick={prevPage}
//                 disabled={activePage === 1}
//               >
//                 <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
//               </IconButton>
//               <Typography color="gray" className="font-normal">
//                 Page <strong className="text-gray-900">{activePage}</strong> of{" "}
//                 <strong className="text-gray-900">
//                   {Math.ceil(
//                     (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
//                   )}
//                 </strong>
//               </Typography>
//               <IconButton
//                 size="sm"
//                 variant="outlined"
//                 onClick={nextPage}
//                 disabled={
//                   activePage ===
//                   Math.ceil(
//                     (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
//                   )
//                 }
//               >
//                 <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
//               </IconButton>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   )
// }

// export default InsuranceCompanyView

import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { BiPlus, BiTrash } from "react-icons/bi"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import {
  GET_ALL_DISTRICTS,
  GET_ALL_TOWNS,
  GET_REGIONS,
} from "../../../GraphQl/queries"

import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type BranchType = {
  code: string
  name: string
}

interface CreateBranchType extends BranchType {
  region: string
  district: string
  town: string
  email: string
  address: string
}

const SetupBranchView = () => {
  const [searchText, setSearchText] = useState("")
  const [isLoading, setLoading] = useState(true)
  const [branches, setBranches] = useState<BranchType[]>()
 

  const {
    companyId,

    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting},
  } = useForm<CreateBranchType>()

  const {
    data: regions,
    loading,
 
  } = useQuery<{ regions: { id: string; name: string }[] }>(GET_REGIONS)

  const {
    data: districts,
    loading: loadingDistrict,
    refetch: refreshDistrict,
  } = useQuery<{ districts: { id: string; name: string }[] }>(
    GET_ALL_DISTRICTS,
    {
      variables: {
        regionId: watch("region"),
      },
    }
  )

  const {
    data: towns,
    loading: loadingTowns,
    refetch: refreshTowns,
  } = useQuery<{ towns: { id: string; name: string }[] }>(GET_ALL_TOWNS, {
    variables: {
      districtID: watch("district"),
    },
  })

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  // console.log(data)

  const fetehBranches = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get-all-branches`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            company_id: companyId,
          }),
        }
      )
      const req = await response.json()
      if (response.ok && !req?.error) {
        setBranches(req?.data)
        console.log(req)
      } else {
        toast.error(req?.data ?? "Something went wrong?")
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.message ?? "Error fetching branches")
    }
  }

  const filterCampaign = (campaign: BranchType) => {
    const isTextMatch =
      campaign?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
      campaign?.code?.toLowerCase()?.includes(searchText.toLowerCase())

    return isTextMatch
  }

  //---------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])


  const totalItems = branches?.filter(filterCampaign)
  // const paginatedData = totalItems?.slice(startIndex, endIndex)

  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)


  const onSubmit: SubmitHandler<CreateBranchType> = async (data) => {
    try {
      const response = await fetch(`${BASEURL}/setup/add-branch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          region: data?.region,
          district: data?.district,
          town: data?.town,
          email: data?.email,
          address: data?.address,
          name: data?.name,
          code: data?.code,
          company_id: companyId,
        }),
      })
      const req = await response.json()
      if (response.ok && !req?.error) {
        toast.success("Branch created successfully")
        fetehBranches()
        reset()
      } else {
        toast.error(req?.data ?? "Something went wrong?")
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating branch")
    }
  }

  useEffect(() => {
    fetehBranches()
  }, [])

  useEffect(() => {
    refreshDistrict()
  }, [watch("region")])

  useEffect(() => {
    if (watch("district") !== "Choose option Loading..") {
      refreshTowns()
    }
  }, [watch("district")])

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="branches" />

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">New branch</Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Name
                    </Typography>
                    <Input
                      value={undefined}
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Code
                    </Typography>
                    <Input
                      value={undefined}
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("code", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Email
                    </Typography>
                    <Input
                      value={undefined}
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Region
                    </Typography>
                    <select
                      id="countries"
                      className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("region", { required: true })}
                    >
                      <option selected>
                        Choose option {loading && "Loading.."}
                      </option>
                      {regions?.regions?.map((region) => (
                        <option
                          key={region?.id}
                          value={region?.id}
                          label={region?.name}
                          className="capitalize"
                        >
                          {region?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      District
                    </Typography>
                    <select
                      id="countries"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("district", { required: true })}
                    >
                      <option selected>
                        Choose option {loadingDistrict && "Loading.."}
                      </option>
                      {districts?.districts?.map((district) => (
                        <option
                          key={district?.id}
                          value={district?.id}
                          label={district?.name}
                          className="capitalize"
                        >
                          {district?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Town
                    </Typography>
                    <select
                      id="countries"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("town", { required: true })}
                    >
                      <option selected>
                        Choose option {loadingTowns && "Loading.."}
                      </option>
                      {towns?.towns?.map((town) => (
                        <option
                          key={town?.id}
                          value={town?.id}
                          label={town?.name}
                          className="capitalize"
                        >
                          {town?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Address
                    </Typography>
                    <Input
                      value={undefined}
                      crossOrigin="anonymous"
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("address", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="flex flex-row items-center  font-normal"
              >
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  <span className="flex flex-row items-center">
                    <BiPlus size={20} />
                    Save
                  </span>
                )}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              Added branches
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["name", "code", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item: BranchType, index: number) => {
                    const isLast = index === [{}]?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.code}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Tooltip content="Delete">
                            <IconButton color="red" size="sm">
                              <BiTrash />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {isLoading && (
                <div className="w-full my-5 flex items-center justify-center">
                  <Spinner />
                </div>
              )}

              {!branches && !isLoading && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography variant="h6">No data found</Typography>
                </div>
              )}

              {paginatedData?.length! === 0 && !loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="white" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}
            </CardBody>
            {paginatedData && paginatedData?.length !== 0 && !loading && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />
              <WhitePaginateComponent
              totalPages={totalItems?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
            />
            )}
           
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default SetupBranchView

import {
  Button,
  Card,
  CardBody,

  Input,
  Spinner,

  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState } from "react"
import {

  DetailDrawer,

} from "../../../components"
import { DEFAULTPRIMARYCOLOR } from "../../../util"

import { toast } from "react-toastify"

import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useQuery } from "@apollo/client"
import {
  COMMISSIONAGGREGATE,
  GETALLPAYABLECOMMISSIONS,
  GETBUSINESSCLASSES,
} from "../../../GraphQl/queries"
import { CommissionAggregateType } from "../../../types/query"
import CommissionDetail from "../../../components/Details/commission-detail"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import ConfirmationDialog from "../../../config/confirmationbox"
import moment from "moment"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { DriverModal } from "../../../components/modal/DriverModal"
import Otp from "../../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"
export type CommissionType = {
  commission_name: string
  payable_commission_id: string
  account_details: {
    branch: string
    bank_name: string
    acct_number: string
  }
  agent_first_name: string
  premium: string
  policy_number: string
  agent_last_name: string
  staff_name: string | null
  user_type: string
  created_at: string
  // commission_name: 'GCAP Commission',
  sub_class_id: string
  risk_id: string
  risk_or_sub_class_name: string
  reference_amount: number
  gross_amount: number
  rate: string
  net_amount: number
  total_tax_deductions: number
  status: string
  business_class_name: string
}

export type SubClassType = {
  id: string
  name: string
  status: string
  description: string
  business_class: {
    name: string
    description: string
  }
}

const CommissionsView = () => {
  const [searchText, setSearchText] = useState("")

  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<CommissionType>()
  const [openOtp, setOpenOtp] = useState(false)
  const [requestType, setRequestType] = useState("")

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const {
    companyId,

    user: stateUser,
    token: stateToken,
  } = useSelector((state: RootState) => state.auth)
  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const {
    data: commissions,
    loading: loadingCommissions,
    refetch: refetchallcom,
  } = useQuery<{
    view_payable_commissions_company: CommissionType[]
  }>(GETALLPAYABLECOMMISSIONS, {
    variables: {
      id: companyId,
      status_type: "pending",
      com_type: "other",
    },
  })



  const { data: classes, loading: loadingClass } = useQuery<{
    business_classes: {
      id: string
      image_url: string
      name: string
      description: string
    }[]
  }>(GETBUSINESSCLASSES)

  const {
    data: commissionAggregate,
    loading: loadingAggregate,

  } = useQuery<CommissionAggregateType>(COMMISSIONAGGREGATE, {
    variables: {
      id: companyId,
      code: "BDD",
    },
  })

  // console.log(commissions)



  // const {
  //   register: updateRegister,
  //   handleSubmit: updateSubmit,
  //   setValue,
  //   formState: { errors: updateError, isSubmitting: isUpdateSubmitting },
  // } = useForm<UpdateType>({
  //   defaultValues: defaultValues,
  // });



  const handleRowClick = (item: CommissionType) => {
    setSelectedRow(item)
    setOpenDrawer(true)
  }

  const handleFilterSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const reset = () => {
    setSearchText("")
  }

  const filterData = (data: CommissionType) => {
    const date = moment(data.created_at).format("LLL")
    if (searchText === "") {
      return data
    } else if (
      data.commission_name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.policy_number.toLowerCase().includes(searchText.toLowerCase()) ||
      data.business_class_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      data.risk_or_sub_class_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      data.risk_id.toLowerCase().includes(searchText.toLowerCase()) ||
      date.toLowerCase().includes(searchText.toLowerCase()) ||
      data.agent_first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      data.gross_amount.toString().includes(searchText) ||
      data.rate.toString().includes(searchText) ||
      data.net_amount.toString().includes(searchText) ||
      data.total_tax_deductions.toString().includes(searchText) ||
      data.user_type.toLowerCase().includes(searchText.toLowerCase()) ||
      data.created_at.toLowerCase().includes(searchText.toLowerCase()) ||
      data.status.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return data
    }
  }
  //-------------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])




  const totalItems = commissions?.view_payable_commissions_company
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.created_at)
      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  // const paginatedData = totalItems?.slice(startIndex, endIndex)
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  // console.log(paginatedData)

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter

    const type = commissions?.view_payable_commissions_company?.map((item: CommissionType) => ({
      commission_name: item?.commission_name,
      policy_number: item?.policy_number,
      business_class_name: item?.business_class_name,
      risk_or_sub_class_name: item?.risk_or_sub_class_name,
      risk_id: item?.risk_id,
      premium: item?.reference_amount,
      rate: item?.rate,
      gross_commission: item?.gross_amount,
      tax: item?.total_tax_deductions,
      net_commission: item?.net_amount,
      payee: item?.user_type,
      date_created: item?.created_at,
      status: item ? (item?.status === "pending" ? "Pending" : "Paid") : "",
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"FT-pending-commissions"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  //---------------------------

  // Filtered and paginated data for the current page
  // const paginatedData = commissions?.view_payable_commissions_company
  //   ?.filter(filterData)
  //   .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedData) {
      const allRows = paginatedData.map((item) => item.payable_commission_id)
      setSelectedRows(allRows)
    } else {
      setSelectedRows([])
    }
  }

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId])
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId))
    }
  }



  const [loadingCreating, setLoadingCreating] = useState(false)
  const [loadingRow, setLoadingRow] = useState("")
  const handleCreateSinglePaymentOrder = async (data: string) => {
    try {
      setLoadingCreating(true)
      setLoadingRow(data)
      const payload = JSON.stringify([
        {
          commission_payable_id: data,
        },
      ])

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-payment-request?usertype=${DASHBOARD_USERTYPE.company}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: payload, // Place the body outside the headers object
        }
      )

      if (!response.ok) {
        toast.error("Error maaking payment order")
      }

      setLoadingCreating(false)
      // if (response.ok) {

      // } else {
      //   toast.error(req?.msg)
      // }
      toast.success("Payment order created successfully")
      refetchallcom()
      setLoadingCreating(false)
    } catch (error) {
      toast.error("Error deleting admin")
    }
  }

  const handleCreateMultiplePaymentOrder = async () => {
    try {
      setLoadingCreating(true)

      const payload = JSON.stringify(
        selectedRows.map((row) => ({
          commission_payable_id: row,
        }))
      )

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-payment-request?usertype=${DASHBOARD_USERTYPE.company}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: payload, // Place the body outside the headers object
        }
      )

      const req = await response.json()
      if (response.ok) {
        refetchallcom()
        toast.success("Payment order created successfully")
      } else {
        toast.error(req?.msg)
      }
    } catch (error) {
      toast.error("Error deleting admin")

    } finally {
      setLoadingCreating(false)
    }
  }


  const [showConfirmation, setShowConfirmation] = useState(false)
  const [chosenId, setChosenId] = useState("")

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])



  return (
    <>
      <DetailDrawer
        size={450}
        title="Commission detail"
        open={openDrawer}
        children={<>{<CommissionDetail content={selectedRow} />}</>}
        onClose={() => setOpenDrawer(false)}
      />
      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"xs"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() =>
              requestType === "single"
                ? handleCreateSinglePaymentOrder(chosenId)
                : handleCreateMultiplePaymentOrder()
            }
            onClose={() => setOpenOtp(false)}
          />
        }
      // () => handleCreateSinglePaymentOrder(chosenId)
      />

      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={() => setOpenOtp(true)}
      />

      <div className="">
        <CustomHeader title="Commissions" />
        <div className="mb-5 w-full grid-cols-1 grid sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5">
          <Card className="bg-brand-yellow rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer h-full ">
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    commissionAggregate?.all_types_of_commissios?.length
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Commission Types
                </Typography>
              </div>
            </div>
          </Card>

          {commissionAggregate?.all_types_of_commissios?.map((item) => (
            <Card
              key={item?.commission_id}
              className="bg-brand-info rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer w-full h-full"
            >
              <div className="flex flex-row justify-between text-center">
                <div>
                  <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                    {loadingAggregate ? (
                      <Spinner className="mb-2 text-white" />
                    ) : (
                      "GH₵" +
                      ((item?.total_amount as any) ?? 0).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    )}
                  </Typography>
                  <Typography className="text-sm uppercase font-normal text-white">
                    {item?.commission_name}
                  </Typography>
                </div>
              </div>
            </Card>
          ))}

          <Card className="bg-brand-success rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (commissionAggregate?.Motor_comprehensive?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  motor comprehensive
                </Typography>
              </div>
            </div>
          </Card>
          <Card className="bg-brand-danger rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (commissionAggregate?.Motor_third_party?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  motor third party
                </Typography>
              </div>
            </div>
          </Card>
          <Card className="bg-brand-purple rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (commissionAggregate?.Motor_third_party_fire?.aggregate
                        ?.sum?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Motor_third_party_fire
                </Typography>
              </div>
            </div>
          </Card>
          <Card className="bg-brand-info rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between text-center">
              <div>
                <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                  {loadingAggregate ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (commissionAggregate?.Home_owners?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  home owners policy
                </Typography>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-7 flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="flex flex-row items-center w-full ">
              <Typography className="w-28">Filter by</Typography>
              {/* <select
                value={searchText}
                onChange={handleSearch}
                className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled>
                  Select filter
                </option>
                <option value={"pending"} label="Pending">
                  Pending
                </option>
                <option value={"paid"} label={"Paid"}>
                  Paid
                </option>
              </select> */}

              <div className="w-[30%]">
                <select
                  value={searchText}
                  onChange={handleFilterSearch}
                  className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>
                    {loadingClass ? "Loading classes" : "Select class"}
                  </option>
                  {classes?.business_classes?.map((item) => (
                    <option
                      className="capitalize"
                      value={item?.name}
                      key={item?.id}
                      label={item?.name}
                    >
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="w-full">
            <Input
              crossOrigin="anonymous"
              type="date"
              value={searchText}
              onChange={handleSearch}
            />
          </div> */}

            <div>
              <Button onClick={reset} className="capitalize">
                Reset
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
            <div className=" w-full bg-black pt-5 rounded-t-2xl">
              <Typography
                variant="h6"
                style={{
                  color:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
              >
                List of All Commissions
                {commissions?.view_payable_commissions_company && (
                  <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                    {commissions?.view_payable_commissions_company?.length}
                  </span>
                )}
              </Typography>
            </div>
            <div className=" w-full">
              <div className="w-full  flex flex-col gap-y-4 bg-white p-3">
                <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row pl-10">
                  <Tooltip content="Export as CSV">
                    <Button
                      onClick={handleDownload}
                      variant="filled"
                      className="capitalize text-sm font-normal flex flex-wrap gap-2"
                      size="sm"
                    >
                      <FiDownloadCloud size={20} />
                      <span>Download csv</span>
                    </Button>
                  </Tooltip>
                </div>
                <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                  {selectedRows?.length > 0 && (
                    <Button
                      onClick={() => {
                        setRequestType("multiple")
                        setOpenOtp(true)
                      }}
                      size="sm"
                      color="teal"
                      className="text-xs font-normal capitalize bg-brand-cyan"
                    >
                      {loadingCreating ? "loading..." : "Request to pay"}
                    </Button>
                  )}

                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      value={searchText}
                      onChange={handleSearch}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                  <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">
                        From
                      </label>
                      <Input
                        type="datetime-local"
                        value={startDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleStartDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="font-semibold text-gray-700">To</label>
                      <Input
                        type="datetime-local"
                        value={endDate.slice(0, -1)} // Remove 'Z' for input value
                        onChange={handleEndDateChange}
                        size="md"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CardBody className="px-0 py-0">
              <div
                className="overflow-x-auto overflow-y-auto"
                style={{ maxHeight: "65vh" }}
              >
                <table className="w-full bg-white min-w-max table-auto">
                  <thead className="bg-white sticky z-50 top-0">
                    <tr>
                      <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedRows.length === paginatedData?.length
                          }
                        />
                      </th>
                      {[
                        "Commission Type",
                        "Policy No.",
                        "Class",
                        "Subclass",
                        "Risk ID",
                        "Basic Premium",
                        "NIC Fees",
                        "Rate",
                        "Gross Commission",
                        "Tax",
                        "Net Commission",
                        "Payee",
                        "Payee Type",
                        "Date created",
                        "Status",
                        "",
                        "",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item, index: number) => {
                      const isLast = index === paginatedData?.length - 1


                      const className = isLast
                        ? "px-2 py-3  text-center"
                        : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                      return (
                        <tr
                          key={index.toString()}
                          // onClick={() => handleRowClick(item)}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td className={className}>
                            <input
                              type="checkbox"
                              onChange={(event) =>
                                handleRowCheckboxChange(
                                  event,
                                  item.payable_commission_id
                                )
                              }
                              checked={selectedRows.includes(
                                item.payable_commission_id
                              )}
                            />
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.commission_name}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.policy_number}
                            </Typography>
                          </td>


                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.business_class_name}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.risk_or_sub_class_name}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.risk_id}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHS{" "}
                              {(
                                parseFloat(item?.reference_amount as any) ?? 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              GHS{" "}
                              {(45).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.rate}%
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GH₵{" "}
                              {(item?.gross_amount ?? 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GH₵{" "}
                              {(
                                parseFloat(item?.total_tax_deductions as any) ??
                                0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GH₵{" "}
                              {(
                                parseFloat(item?.net_amount as any) ?? 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {`${(item.user_type === "agent" || item.user_type === "broker")
                                  ? `${item.agent_first_name} ${item.agent_last_name}`
                                  : item.staff_name !== null
                                    ? item.staff_name
                                    : ""
                                }`}

                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.user_type}

                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {moment(item?.created_at).format("LLL")}
                            </Typography>
                          </td>

                          <td className={className}>
                            <div className="flex flex-col gap-2">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="text-xs capitalize font-bold"
                                style={{
                                  fontWeight: "bolder",
                                  color:
                                    item?.status === "paid"
                                      ? "green" // If status is "paid", make text green
                                      : item?.status === "processing"
                                        ? "orange" // If status is "processing", make text yellow
                                        : item?.status === "pending"
                                          ? "blue" // If status is "pending", make text blue
                                          : "inherit", // Use default color if status doesn't match any condition
                                }}
                              >
                                <span className="font-normal">
                                  {item?.status}
                                </span>
                              </Typography>
                            </div>
                          </td>

                          <div className="flex justify-between whitespace-nowrap z-50 bg-white sticky right-0">
                            <td className={className}>
                              <div className="flex items-center gap-1">
                                <Button
                                  onClick={() => handleRowClick(item)}
                                  size="sm"
                                  color="teal"
                                  className="text-xs font-normal capitalize bg-brand-teal"
                                >
                                  View details
                                </Button>
                                {/* <Button
                            onClick={() => {
                            
                            }}
                            size="sm"
                            color="teal"
                            className="text-xs font-normal capitalize bg-brand-cyan"
                          >
                            Edit rates
                          </Button> */}
                                <Button
                                  onClick={() => {
                                    setRequestType("single")
                                    setChosenId(item?.payable_commission_id)
                                    setShowConfirmation(true)
                                  }}
                                  size="sm"
                                  disabled={
                                    loadingRow === item?.payable_commission_id
                                  }
                                  color="teal"
                                  className="text-xs font-normal capitalize bg-brand-cyan"
                                >
                                  {loadingRow === item.payable_commission_id
                                    ? "Loading"
                                    : "Request to pay"}
                                </Button>
                              </div>
                            </td>
                          </div>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
            {loadingCommissions && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            )}

            {!paginatedData && !loadingCommissions && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loadingCommissions && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="white" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
          </CardBody>

          {/* Paginator */}
          {paginatedData &&
            paginatedData?.length !== 0 &&
            !loadingCommissions && (
              // <GeneralPagination
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <PerPageAndPagination
                total={totalItems?.length!}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={itemsPerPage}
                setPerPage={setItemsPerPage}
              />
            )}
        </div>
      </div>
    </>
  )
}

export default CommissionsView
